export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a senha?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira vez aqui?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie uma conta"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperação de senha"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone ou e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperação de senha"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do meio:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário encontrado"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros/Comentário"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma operação encontrada"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma das operações"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receitas:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" unidades no valor de"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de operações"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos, pagamento de serviços"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências internas"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento de conta"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências entre contas próprias"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de cheques cripto"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativação de cheques cripto"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita/Despesa"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesa"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= igual"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ não igual"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> maior"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ maior ou igual"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menor"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menor ou igual"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído com sucesso"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processamento"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de operações"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar conta"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca na bolsa"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar endereço"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências dentro da própria carteira"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte digital"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais detalhes"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Usuário:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de registro:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última autorização:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha de confirmação"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar operações, você precisa criar uma senha de confirmação."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos populares"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notícias"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum ativo"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de registro"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No grupo"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de pagamentos"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de transferência"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta a recarregar"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar conta"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da transferência"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar transferência"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre transferências"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar endereço"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço a recarregar"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a lista de tarefas"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de transferência"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operação"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de operações"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contas"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro desconhecido"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tokens"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de transferência:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção de transação"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de proteção:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dias"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como modelo"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir para outro usuário"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de proteção é um código que o destinatário deve inserir para completar a transação. A transferência protegida será imediatamente visível para o destinatário na seção \"Histórico de operações\", mas ele só poderá usar o dinheiro após inserir o código de proteção no cartão de transação. Se o prazo do pagamento expirar, os fundos serão devolvidos ao remetente."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de transferência"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências entre contas próprias"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências dentro da carteira"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações adicionais"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo endereço"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar nova conta"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir selecionados"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contas"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da conta"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir as contas?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir a conta?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum ativo encontrado"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta encontrada"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear endereço"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir os endereços?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir o endereço?"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de endereço"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de conta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar endereço"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo endereço"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar nova conta"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar antiphishing é um mecanismo adicional de proteção ao trabalhar com a conta."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frase de boas-vindas definida por você será exibida na parte superior de qualquer página da conta."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A frase correta mostrará que você está no site correto, enquanto uma frase incorreta será um sinal: não realize operações e entre em contato imediatamente com o serviço de segurança do nosso sistema."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar antiphishing:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase antiphishing:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As alterações entrarão em vigor após o próximo login"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar o acesso por endereço IP impedirá você (ou um invasor) de acessar o sistema a partir de um endereço não incluído na lista de endereços permitidos."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restringir o acesso à conta por endereços IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereços"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloco de endereços: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de endereços"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do endereço"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para endereço"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço disponível"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibir"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o endereço IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço IP inválido"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP ou bloco de endereços especificado."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum endereço IP permitido para autorização. Você não conseguirá acessar sua conta."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso por IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palavra mágica é uma configuração de segurança adicional."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a palavra mágica estiver ativada, toda vez que você fizer login, o sistema pedirá que você insira alguns caracteres (em sua ordem específica) da palavra mágica."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar a palavra mágica:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao entrar na conta:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao pagar via comerciante:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao usar o cartão:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra mágica"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As chaves de uso único aumentam significativamente a segurança do sistema."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento do login, o programa pedirá que você insira não apenas o login e a senha, mas também a próxima chave de uso único. A ativação é feita sequencialmente. A última chave é válida até que você crie uma nova série de chaves."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar chaves de uso único"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de chaves"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaves de uso único"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha antiga:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nova senha\" e \"Confirmação de senha\" não coincidem."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar senha"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 63 caracteres. Caracteres permitidos: letras latinas, números e símbolos"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha antiga:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação de senha:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nova senha\" e \"Confirmação de senha\" não coincidem."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha não deve conter caracteres cirílicos"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar senha de confirmação"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar senha de confirmação"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a página inicial"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 4 a 65 caracteres. Caracteres permitidos: letras latinas, números e símbolos"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você esquecer a senha para acessar a conta, poderá recuperá-la respondendo à pergunta secreta."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesta seção, você pode alterar a pergunta/resposta secreta."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta secreta"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua pergunta:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta secreta"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua pergunta"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par de negociação:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de troca:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca de mercado - será usada a taxa de câmbio mais vantajosa no momento da execução da ordem. Atenção, a quantia a receber pode variar e diferir da calculada dependendo das ordens no momento da troca."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca a preço fixo ocorre de acordo com a quantia e a taxa especificadas por você. Uma ordem será colocada. Se a taxa especificada for muito diferente da taxa de mercado, o fechamento da ordem pode levar muito tempo."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa (previsão):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa não determinada, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recomenda-se definir a taxa manualmente"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar tudo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo mercado"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(troca rápida pela melhor oferta correspondente)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa fixa "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(colocar uma ordem com a taxa especificada)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta adequada"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração em 24 horas"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flutuações em 24 horas"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume de negociações em 24 horas"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quantia não pode ser zero!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trocar"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver o resumo, selecione um par de troca"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível trocar os mesmos ativos"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado. Resumo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas semelhantes"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas correspondentes"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas ordens"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas negociações"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e hora"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxa"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma oferta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma negociação"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma ordem"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente preenchida"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento não iniciado"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento iniciado"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente cancelada"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida e cancelada"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as ordens"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as ordens"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dando"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebendo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxa"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma ordem"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia mínima dada"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia máxima dada"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia mínima recebida"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia máxima recebida"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tentativas de transação"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo dado"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo recebido"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de crédito"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de subdistribuidores"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de data não selecionado"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Término"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual parte é fixa e qual é variável"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O montante a dar é fixo"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quantia a receber é fixa"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de ordem"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário deseja trocar rapidamente pelas melhores ofertas correspondentes"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca a uma taxa estritamente definida"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento não iniciado"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento iniciado"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente preenchida"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamente cancelada"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente preenchida e cancelada"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo dado"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo recebido"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de data"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar quantia"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber quantia"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negociações"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma oferta"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do vendedor"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código da oferta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-ativo"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor verificado"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas para usuários verificados"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível negociar consigo mesmo"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cripto-ativos"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum cripto-ativo"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão de token"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceite"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primário"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundário"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparado com outro ativo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações detalhadas sobre o ativo criptográfico"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar dados sobre um ativo criptográfico"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema adicional"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir ativo criptográfico"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os tokens podem ser adquiridos em uma criptomoeda"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os tokens podem ser vendidos em uma criptomoeda"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibindo tokens"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para um usuário específico"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID do usuário"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome é o nome completo do cripto-ativo, como será visto por todos: o próprio emissor, compradores, vendedores, processadores, etc. Até 40 caracteres."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker é o nome curto do cripto-ativo, uma abreviação de 3 a 10 caracteres."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a categoria que mais se adequa ao seu cripto-ativo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos suportados: png, jpg, jpeg, svg. \nTamanho do arquivo não superior a 1 MB, 500*500 pixels"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva por que este cripto-ativo está sendo emitido pelo emissor, em qual área ele opera ou operará, qual é a sua missão"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo / missão / propósito:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor por unidade:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de casas decimais para a quantia. Por exemplo, a precisão da maioria das moedas fiduciárias, como o dólar americano ou o euro, é 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de unidades do cripto-ativo emitido. O valor da emissão estará disponível imediatamente na sua conta após a conclusão da operação de criação do cripto-ativo."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor declarado por unidade:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantia:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa responsável pelas obrigações:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisão sobre a emissão do cripto-ativo:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar operações para o blockchain:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de blockchain:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar operações com o cripto-ativo:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições/Sanções:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique a quais cidadãos ou residentes fiscais de quais países da lista fornecida é proibido o uso do cripto-ativo:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras restrições e condições:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de hospedagem:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do whitepaper:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros contatos:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o anterior"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar cripto-ativo"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e propósito"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financeiras"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar para o blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites e restrições"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não definido"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculação fixa a moedas fiduciárias e outros ativos"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissor (Pessoa emitindo o cripto-ativo)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertante (Pessoa distribuindo o cripto-ativo)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remetente"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu (incluir patrocínio)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuição do cripto-ativo"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-ativo significa uma representação digital de valor ou direito que pode ser transferida e armazenada eletronicamente usando tecnologia blockchain. Cripto-ativos NÃO incluem moedas fiduciárias, títulos e outros instrumentos financeiros e ativos sujeitos a regulamentação estatal separada."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar ativo criptográfico"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administração"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo da recusa em aceitar"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta do ofertante"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta do ofertante na moeda vinculante"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertante é o representante do emissor de um ativo nas operações de compra/venda no mercado primário. \nA conta é passiva. \nPode ser uma conta de emissão de ativos"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conta é passiva, vinculada em moeda. \nDeve pertencer ao mesmo usuário da conta anterior"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que os usuários comprem um ativo em caixas eletrônicos"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário de compra pós-venda"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transações do mercado secundário são realizadas através de gateways"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário de vendas pós-venda"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transações do mercado secundário são realizadas através de gateways"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transações com um ativo na bolsa"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transações de ativos via P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você é empresário individual ou representante de pessoa jurídica, é necessário baixar o arquivo de decisão de liberação de ativo digital em formato PDF. \nA solução deve conter todas as principais características do ativo virtual e ser assinada com assinatura eletrônica do empresário pessoa física ou desta pessoa jurídica. \nUm exemplo de decisão de emissão de ativo digital pode ser encontrado nas páginas do nosso site na seção \\\"Documentos\\\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocando um ativo virtual"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir conta de emissão"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione nas contas do emissor"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos virtuais"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de notificações"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre operações"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização (login na conta pessoal, aplicativo móvel, etc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar ao realizar \"Operações financeiras usando cartão\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas (qualquer pagamento, transferência, pagamento de serviços, etc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de fundos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de mensagem interna"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebimento de fatura para pagamento"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de configurações de segurança"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar operações com código da mensagem"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar autorização com código da mensagem"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar ao realizar \"Operações financeiras usando cartão\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar alteração de configurações de segurança com código da mensagem"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar despesas com código da mensagem"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações não salvas, salvar?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não salvar"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de recebimento"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de processamento"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pagamentos"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incluindo bem-sucedidos"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incluindo com erros"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos em massa"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedor de serviços"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1º parâmetro"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum pagamento encontrado"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode fazer vários pagamentos simultaneamente. Para isso, você precisa criar um arquivo com a descrição dos pagamentos nos formatos \"XLS\", \"XLSX\", \"CSV\" ou \"XML\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de arquivo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do arquivo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pagamentos: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no valor total de: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo de pagamentos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer pagamentos"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar arquivo de pagamentos"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos e transferências em massa"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O serviço de pagamentos em massa permite transferir rapidamente fundos para outros usuários e realizar operações de pagamento para provedores de serviços."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na seção"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar arquivo de pagamentos"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você pode carregar um arquivo nos formatos Excel (XLS, XLSX, CSV) ou XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tarefas"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permite visualizar os status dos pedidos de pagamento em massa criados anteriormente."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode criar um arquivo no editor de planilhas Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar exemplo de arquivo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações importantes:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de arquivo - CSV, XLS ou XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve estar sem cabeçalhos."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que números longos não sejam convertidos para formato exponencial (por exemplo, \"5,46546E+15\") e zeros à esquerda não sejam removidos, você pode colocar um apóstrofo (') antes do número ou definir o formato da célula como texto antes de inserir os dados."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para pagamentos a provedores de serviços:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do serviço;"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de recebimento do serviço;"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas obrigatórias: 1, 2 e 3."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para transferências a usuários:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da conta de recebimento;"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas obrigatórias: 1 e 2."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a quantia do pagamento for superior ao saldo da conta, todos os pagamentos serão rejeitados."])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve conter as seguintes colunas: Nome do serviço, Conta de recebimento do serviço, Quantia do pagamento, Comentário. Todas as colunas são obrigatórias."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o nome do serviço ou a conta de recebimento do serviço não estiverem corretos, o pagamento será rejeitado."])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato das colunas para transferências a usuários:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do serviço;"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de recebimento do serviço;"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento;"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário;"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas obrigatórias: 1 e 2."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de arquivo"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a quantia do pagamento for superior ao saldo da conta, todos os pagamentos serão rejeitados."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O arquivo deve conter as seguintes colunas: Nome do serviço, Conta de recebimento do serviço, Quantia do pagamento, Comentário. Todas as colunas são obrigatórias."])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o nome do serviço ou a conta de recebimento do serviço não estiverem corretos, o pagamento será rejeitado."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O montante deve ser especificado na moeda do produto de crédito."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário ao crédito"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é obrigatório ser preenchido, pode ser deixado em branco; o comprimento não pode exceder 255 caracteres."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação do mutuário"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-se que o mutuário tem de ter um passaporte digital Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do produto de empréstimo"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode visualizá-lo na lista de produtos de empréstimo no desembolso manual do empréstimo"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplesmente o número de períodos (os períodos utilizados são definidos no produto de empréstimo, por exemplo, podem ser dias, semanas, meses, etc.)."])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do operador (número do formulário)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O preenchimento deste campo e dos seguintes é facultativo; se não forem preenchidos, o mutuário poderá efetuar mais tarde o levantamento do empréstimo por sua conta pessoal."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar em caso de erro"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar (1) o empréstimo emitido se a transação de levantamento for concluída com um erro, ou deixá-lo para posterior levantamento pelo próprio (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor do parâmetro exigido pelo operador, por exemplo, um número de telefone. Muitos operadores estão limitados a um parâmetro de pagamento, mas se o número de parâmetros for superior a um, é necessário especificá-los todos nas colunas adjacentes à primeira."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento 2."])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro de pagamento n.º"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última mensagem"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma solicitação"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridade"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto da mensagem"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode carregar um ou mais arquivos. Tamanho máximo do arquivo - 10MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixa"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte técnico"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitações ativas"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova solicitação"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processamento pelo operador"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação criada"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabalhando, aguarde a resposta do operador"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação fechada"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação fechada"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem novas mensagens"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova mensagem do operador"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem novas mensagens"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para o arquivo"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reabastecimento"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem modelos"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir o modelo Nº"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear modelo"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do contratante"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Conta / E-mail / Telefone"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Endereço cripto / E-mail / Telefone"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações gerais"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de controle"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus lojas"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojas não encontradas"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar código de widget"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar link de pagamento"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir loja"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você realmente deseja excluir a loja"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagamento"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar loja"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A loja eletrônica registrada no sistema pode aceitar pagamentos de clientes através do merchant do nosso sistema."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podem ser registradas como loja lojas eletrônicas, pontos de troca e quaisquer outros recursos da internet que desejem aceitar pagamentos."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome ou marca comercial"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL da loja"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição curta da loja"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de serviço"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja ativa"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave secreta"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir pagamentos automáticos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar resultados para o script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do script"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar após pagamento bem-sucedido"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após pagamento não bem-sucedido"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 30 caracteres. Caracteres permitidos: letras latinas, números"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado para verificar a integridade e autenticidade dos dados transmitidos pelo sistema de pagamento (ao enviar o usuário do merchant) e pela loja (ao receber os resultados do pagamento)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para a lista de lojas"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de faturas"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturas não encontradas"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loja"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo externo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinônimos"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se preenchido, o anúncio estará disponível apenas para usuários dos países especificados"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular ao ativo"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações adicionais"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção da transferência"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O anúncio está ativo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas por link"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas para usuários verificados"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o anterior"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrições"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de anúncio"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu ativo externo"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não vincular"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzir taxa"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverter"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta nesse ativo"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir taxa"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa correta"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzir taxa"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverter"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo maior que o máximo"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde e como deseja receber a transferência. Exemplo: Transferir no aplicativo do banco pelo número de telefone +3519990001111 (destinatário Alexandre N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dar"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receber"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes solicitados"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quais detalhes o tomador precisa enviar para que você possa fazer a transferência"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ativo"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que você deseja receber: fundos em um ativo interno, por exemplo, BTC, ou em um ativo externo, por exemplo, TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escreva um comentário sobre as características desta transação, por exemplo: \\\"O ativo externo é transferido apenas em um encontro pessoal\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de valores ao qual o ativo externo pertence, por exemplo, \\\"Dinheiro em espécie\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do ativo para exibição na plataforma"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ativo também pode ser chamado por palavras da lista"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência com um ativo existente"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo: litros, peças, horas, caixas"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo: l, un, h, cx"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantos dígitos podem existir após a vírgula nos valores do ativo"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você escolheu criar um ativo ainda não existente, por favor preencha suas características"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo que é dado às partes para cumprir as condições"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ativo interno é um ativo que existe no sistema \\\"New Reality\\\". Um ativo externo é um ativo que não existe no sistema \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não vinculado"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar negociação"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar participação na negociação"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar negociação"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estender tempo de espera"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar negociação"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status do usuário na negociação não definido. Algo deu errado"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar Arbitragem"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concordar com Arbitragem"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre a transferência"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar recebimento da transferência"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar negociação"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar avaliação"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação Nº"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoje"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amanhã"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negociações"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem negociações"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefício"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código da negociação"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem negociações ativas"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas negociações"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do ativo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem anúncios"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus anúncios"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus anúncios"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo anúncio"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo externo"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo interno"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrego"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebo"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limites"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicação"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo em conta"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar negociação"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera para ações das partes"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar negociação"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não selecionada"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia fora do intervalo: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ativo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receber"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos internos"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos não encontrados"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos externos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionado:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internos"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externos"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar arbitragem"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ontem"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos externos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado ao ativo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período até"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem ativos"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontem"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado ao ativo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anúncio"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de quantia"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de espera (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anúncio ativo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas por link"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível apenas para usuários verificados"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar ativo externo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinônimos"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular ao ativo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixas eletrônicos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para expandir a tabela para tela cheia"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subconcessionários"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um caixa eletrônico"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar criptomato"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação principal"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do local de instalação"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalizado"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não normalizado"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda-feira"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terça-feira"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta-feira"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta-feira"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexta-feira"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo para almoço"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir senha"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar contas existentes"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novas contas"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações principais"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário de funcionamento (informação de referência)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma máquina criptográfica encontrada"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do caixa eletrônico"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de apoio"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do subrevendedor"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de trânsito"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de um criptomata"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editando um criptomata"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibir"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de colecionadores"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um coletor"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível acessar os saldos de cobrança - sem dados"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de colecionadores"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um coletor"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitação"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar caixa eletrônico"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobrança atuais"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobrança descarregados"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código PIN"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você não especificar um subconcessionário, o caixa eletrônico estará vinculado a você"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude e longitude são coordenadas geográficas que podem ser copiadas, por exemplo, do Google Maps inserindo o endereço do caixa eletrônico"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de instalação"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos uma coluna deve ser selecionada para exibir a tabela"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques eletrônicos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar cheque"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem cheques"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de cheque eletrônico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denominação de cada cheque"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade de cheques"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques criados"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativação de cheque eletrônico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de crédito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de ativação"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops, ocorreu um erro!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de caixas eletrônicos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar coleção"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não mostrar coleções de arquivos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não mostrar coleções canceladas"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do ponto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma coleção"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação de cobrança enviada"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar coleção"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar o status da coleção"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionando coleção"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações básicas"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noções básicas"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do ponto"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade de cartões por aplicação"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitante de contas"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colecionadores de dinheiro"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota para colecionadores"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a coleção"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando fora de ordem"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitua o cassete do aceitador de notas"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre as cartas"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova todos os cartões do cassete dispensador de cartões rejeitados"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova todas as cartas do tubo"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número do ponto é um campo obrigatório que deve ser preenchido"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação 1 da cassete"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de notas no cassete 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade no cassete 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação 2 da cassete"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de notas no cassete 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a fita 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação de cassete"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editando coleção"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da cassete"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da cassete"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de coleção de cassetes"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação de cassete"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa eletrônico"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na verdade"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário sobre a cassete"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para exibir"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dispositivos"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos mortais são levados embora"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa eletrônico"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contas"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuidor"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar/Retrair"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitante de contas"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar cartões"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atual"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descarregado"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldos de cobrança em caixas eletrônicos"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dispositivos"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassete instalada em ATM"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apreendido"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos são levados"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os restos são levados"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cassete vazia:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adesão:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações adicionais:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a data"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o status"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pedido de cobrança foi apresentado"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação de cobrança cancelada"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrecadação aprovada para execução"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cobrança é feita pelo caixa (o dinheiro é colocado em cassetes)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassetes entregues a colecionadores (dinheiro a caminho)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleta de dinheiro instalada no caixa eletrônico"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como resultado de uma falha, sobrecarregado com outra coleção"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A coleta foi substituída por outra coleta em caixa eletrônico (permanece a caminho)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os saldos de cobrança são lançados (a cobrança é enviada para o arquivo)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar informações sobre o coletor"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção da conta do ofertante"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos do formulário Não."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID obrigatório ausente"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você fez login com sucesso, continue as operações no caixa eletrônico"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar transações em caixas eletrônicos"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione colunas para exibir"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar relatório"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amostra"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folha"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta pessoal"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta empresarial"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do meio: (se houver)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen e apóstrofo."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen, espaço e apóstrofo."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas, bem como hífen, aspas, espaços, números e apóstrofo."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São permitidas letras latinas (maiúsculas e minúsculas), números, símbolos _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir senha"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senhas não coincidem"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados do usuário"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique um endereço de e-mail ou número de telefone válido. O número de telefone deve estar no formato internacional, por exemplo: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você foi registrado com sucesso"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora você pode"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autorizar-se no painel pessoal"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta será verificada por nosso funcionário, informaremos os resultados de sua ativação posteriormente"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um e-mail com um link de confirmação foi enviado"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para seu endereço de e-mail"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Após o primeiro login, você será solicitado a confirmar o número de telefone"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não encontrado"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais detalhes"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar usuários"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucesso"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transação"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros gerais"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de saldos nas contas"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No final da operação:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No início da operação:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros adicionais"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operações no blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima tentativa:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteção da transação"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de proteção:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de proteção:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do modelo"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar operação"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operação"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar modelo"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da transação"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondente"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de pagamento"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do formulário de pagamento"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita/Despesa"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração de saldos dos endereços"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar crédito"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver dinheiro ao remetente"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operação suspeita"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de ação:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não encontrado"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite a senha de confirmação"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operação"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de operações"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de contas"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucesso"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro desconhecido"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus ativos"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre novo ativo"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de ativos virtuais"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link para blockchain"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar endereço"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidade"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bairro"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território dentro da cidade"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidade"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rua"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento adicional do endereço"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificação"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação para suporte técnico"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar mensagem"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode carregar um ou mais arquivos. Tamanho máximo do arquivo - 10MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto da mensagem"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criando widget para"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerar"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento em"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia do pagamento"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações em caso de sucesso"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações em caso de falha"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerando código/link de pagamento"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da operação da loja"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetro adicional"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de sucesso"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de falha"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notificação"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após pagamento bem-sucedido"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde o cliente retornará após falha"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL para onde será enviada a notificação dos resultados do pagamento"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar quantia"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link de pagamento"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentação"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade da conta de pagamento em minutos, padrão é 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade do link"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar loja"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você está vendo isso, o formulário não foi recebido (mas você não deveria estar vendo isso)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão adicional"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-ativo"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta - fonte de cobertura"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de débito de comissão"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema adicional"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do problema atual"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FICHA"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revestimento"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculativo:"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para débito"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crédito"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar arquivo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos permitidos:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato desconhecido"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do arquivo muito grande, tamanho máximo:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar ou arrastar arquivos aqui para carregar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivos carregados"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arquivos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links úteis"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras configurações de segurança"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação necessária"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A autorização deve ser confirmada com uma chave única"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A autorização deve ser confirmada com uma palavra mágica"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O registro deve ser confirmado com um código de mensagem SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha de"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caracteres"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor inválido"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para selecionar quais colunas exibir"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linguagem"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bom dia"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boa tarde"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boa noite"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boa noite"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por página"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar pagamento"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular comissão de devolução"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver pagamento"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash no blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crédito"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você concorda com as condições alteradas, confirme o crédito:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se estas condições não forem adequadas, você pode devolver os fundos para a conta do remetente, descontando os custos de devolução:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no valor de"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tem risco AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["portanto, a comissão para seu crédito foi recalculada"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assim, a quantia para crédito é"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma nova entrada encontrada"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pagamento"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolução"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])}
      }
    }
  }
}