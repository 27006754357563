export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रूसी"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगिन:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड भूल गए?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहली बार यहाँ?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएँ"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनर्प्राप्ति"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फोन या ईमेल:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनर्प्राप्त करें"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनर्प्राप्ति"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य नाम:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जन्म तिथि:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोजें"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता मिला"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संबंधी"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर/टिप्पणी"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई ऑपरेशन नहीं मिला"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की राशि"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमदनी:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" बार"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन प्रकार"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान, सेवा भुगतान"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक ट्रांसफर"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल भुगतान"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने खातों के बीच ट्रांसफर"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो चेक बनाना"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो चेक सक्रियण"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद/खर्च"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= बराबर"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ बराबर नहीं"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> से अधिक"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ से अधिक या बराबर"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< से कम"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ से कम या बराबर"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलतापूर्वक पूर्ण"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया में"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन इतिहास"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता पूर्ति"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाता चयनित नहीं"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज पर विनिमय"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता पूर्ति"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने बटुए के भीतर ट्रांसफर"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संवाददाता"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संचालन"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिजिटल पासपोर्ट"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक जानकारी"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यूजर आईडी:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण की तारीख:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम प्रमाणीकरण:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे संसाधन"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी देखें"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरो"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालो"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाते नहीं"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि पासवर्ड"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की पुष्टि करने के लिए आपको एक पुष्टिकरण पासवर्ड बनाना होगा।"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोकप्रिय भुगतान"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाचार"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छोटा करें"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संसाधन नहीं"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएँ"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण विवरण"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि किया गया"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सार्वजनिक"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोजें"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह में"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान समूह"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान राशि"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर खाता"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट सहेजें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरोसेमंद खाता"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान विधि"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट सहेजें"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता पूर्ति"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर विवरण"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर जांचें"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर जानकारी"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्देश"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता पूर्ति"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भरोसेमंद पता"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची में"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर पता"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन दोहराएँ"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन इतिहास"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता सूची"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात त्रुटि"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन सूची"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर खाता:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डील प्रोटेक्शन"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जनरेट करें"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन अवधि:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट नाम:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट के रूप में सहेजें"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दूसरे उपयोगकर्ता को ट्रांसफर"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन कोड वह कोड है जिसे प्राप्तकर्ता को डील पूरी करने के लिए दर्ज करना होगा। संरक्षित ट्रांसफर प्राप्तकर्ता को तुरंत ''ऑपरेशन इतिहास'' में दिखाई देगा, लेकिन वह राशि का उपयोग तभी कर सकता है जब वह ट्रांसफर कार्ड में प्रोटेक्शन कोड दर्ज करेगा। यदि भुगतान की अवधि समाप्त हो जाती है, तो राशि भेजने वाले को वापस कर दी जाती है।"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर पता"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहाँ से"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहाँ तक"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफर"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने खातों के बीच ट्रांसफर"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बटुए के भीतर ट्रांसफर"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ गलत हो गया"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त जानकारी"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकालना"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पता बनाएं"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया खाता बनाएं"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित हटाएं"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे संसाधन"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता सूची"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता नाम"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई खाते हटाना चाहते हैं?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई खाता हटाना चाहते हैं?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संसाधन नहीं मिले"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते नहीं मिले"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का नाम बदलना"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई पते हटाना चाहते हैं?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई पता हटाना चाहते हैं?"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता प्रकार"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता प्रकार"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता बनाएं"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए पते का निर्माण"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए खाते का निर्माण"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग सेटिंग एक अतिरिक्त सुरक्षा तंत्र है।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके द्वारा सेट किया गया स्वागत संदेश किसी भी पेज के शीर्ष पर प्रदर्शित होगा।"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही संदेश आपको दिखाएगा कि आप सही साइट पर हैं, गलत संदेश एक संकेत होगा: ऑपरेशन न करें, हमारी सुरक्षा सेवा से तुरंत संपर्क करें।"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग सक्षम करें:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग स्ट्रिंग:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एंटीफिशिंग"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन अगले प्रमाणीकरण के बाद प्रभावी होंगे"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस से एक्सेस सेटिंग आपको (या हैकर को) किसी सूचीबद्ध पते से काम करने से रोक देगी।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी-एड्रेस के माध्यम से एक्सेस प्रतिबंध"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते जोड़ें"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का ब्लॉक:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका आईपी:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते की सूची"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते से"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते तक"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सेस"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई पते नहीं"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंधित करें"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता दर्ज करें"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी पता गलत है"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता या पते का ब्लॉक निर्दिष्ट नहीं है।"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आईपी पता प्रमाणित नहीं है। आप अपने खाते तक पहुंच प्राप्त नहीं कर सकेंगे।"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईपी के माध्यम से एक्सेस"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द एक अतिरिक्त सुरक्षा सेटिंग है।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि जादुई शब्द सक्षम है, तो हर बार प्रमाणीकरण के समय सिस्टम आपसे जादुई शब्द के कुछ वर्ण पूछेगा।"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द पूछें:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैबिनेट में प्रवेश करते समय:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी से भुगतान करते समय:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड का उपयोग करते समय:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जादुई शब्द"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी सिस्टम की सुरक्षा को काफी बढ़ाती है।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश के समय, प्रोग्राम आपसे न केवल लॉगिन और पासवर्ड, बल्कि अगली वन-टाइम कुंजी भी पूछेगा। सक्रियण क्रमशः होता है। अंतिम कुंजी तब तक प्रभावी रहती है जब तक कि आप नई कुंजी श्रृंखला नहीं बनाते।"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी का उपयोग करें"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी सूची"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पन्न करें"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुंजी"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वन-टाइम कुंजी"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराना पासवर्ड:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"नया पासवर्ड\" और \"पासवर्ड की पुष्टि\" मेल नहीं खाते।"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड बदलें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 से 63 वर्णों की स्ट्रिंग। स्वीकार्य वर्ण: लैटिन वर्णमाला, अंक और प्रतीक"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुराना पासवर्ड:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया पासवर्ड:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड की पुष्टि:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"नया पासवर्ड\" और \"पासवर्ड की पुष्टि\" मेल नहीं खाते।"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड में सिरिलिक वर्ण नहीं होने चाहिए"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बनाएं"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड बदलें"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य पृष्ठ पर जाएं"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 से 65 वर्णों की स्ट्रिंग। स्वीकार्य वर्ण: लैटिन वर्णमाला, अंक और प्रतीक"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप अपने कैबिनेट के पासवर्ड को भूल जाते हैं, तो आप इसे गुप्त प्रश्न का उत्तर देकर पुनः प्राप्त कर सकते हैं।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस अनुभाग में आप गुप्त प्रश्न/उत्तर बदल सकते हैं।"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त प्रश्न"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका प्रश्न:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त प्रश्न"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना प्रश्न"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रेडिंग पेयर:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज प्रकार:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार दर पर हस्तांतरण - सबसे लाभकारी दर का उपयोग किया जाएगा। ध्यान दें, प्राप्त राशि बदल सकती है और अनुरोध के समय की दर के अनुसार भिन्न हो सकती है।"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर दर पर हस्तांतरण आपके द्वारा निर्दिष्ट राशि और दर के अनुसार होता है। अनुरोध प्रस्तुत किया जाएगा। यदि निर्दिष्ट दर बाजार दर से बहुत अलग है, तो अनुरोध को पूरा करने में समय लग सकता है।"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त राशि (अनुमान):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर निर्धारित नहीं है,"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर को स्वयं सेट करने की अनुशंसा की जाती है"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी का उपयोग करें"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार पर"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(बेहतर मुकाबले की पेशकश के साथ तेज़ एक्सचेंज)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर दर"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(निर्धारित दर पर अनुरोध प्रस्तुत करें)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई उपयुक्त खाते नहीं"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में परिवर्तन"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में उतार-चढ़ाव"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे में लेन-देन की मात्रा"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि शून्य नहीं हो सकती!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारांश डेटा देखने के लिए एक्सचेंज पेयर चुनें"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान संपत्तियों का आदान-प्रदान नहीं कर सकते"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाज़ार"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाज़ार. सारांश"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समान प्रस्ताव"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विपरीत प्रस्ताव"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता है"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता है"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे अनुरोध"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले लेन-देन"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी गई राशि"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख और समय"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराएं"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रस्ताव नहीं"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई लेन-देन नहीं"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुरोध नहीं"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण रूप से पूरा हुआ"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ नहीं हुई"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ हुई"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा हुआ"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी तरह से रद्द"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा और रद्द"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी अनुरोध"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी अनुरोध"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दर"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराएं"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुरोध नहीं"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम दी जाने वाली राशि"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम दी जाने वाली राशि"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम प्राप्त करने वाली राशि"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम प्राप्त करने वाली राशि"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन प्रयासों की संख्या"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी जाने वाली संपत्ति"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने वाली संपत्ति"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट खाता"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब-डीलर्स की सूची"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई खाता चयनित नहीं"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख प्रकार चयनित नहीं"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माण"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्ति"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समाप्त"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुरुआत"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कौन सा हिस्सा स्थिर है और कौन सा बदलता है"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिर राशि को निर्धारण"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने वाली राशि को निर्धारण"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध प्रकार"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता जल्दी एक्सचेंज करना चाहता है"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्धारित दर पर एक्सचेंज"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ नहीं हुई"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया प्रारंभ हुई"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा हुआ"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ण रूप से पूरा हुआ"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी तरह से रद्द"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंशिक रूप से पूरा और रद्द"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दी जाने वाली संपत्ति"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने वाली संपत्ति"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख प्रकार"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने की राशि"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने की राशि"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई प्रस्ताव नहीं"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता आईडी"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव कोड"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता सत्यापित है"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने आप से लेन-देन नहीं कर सकते"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति चुनें"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिशन"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्तियों की सूची"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई क्रिप्टो-संपत्तियाँ नहीं"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन जारी करना"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृति"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाजार"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिक"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माध्यमिक"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी अन्य संपत्ति से तुलना की गई"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो संपत्ति के बारे में विस्तृत जानकारी"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो संपत्ति के बारे में डेटा संपादित करें"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त मुद्दा"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो संपत्ति हटाएं"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गतिविधि"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमैट पर टोकन खरीदे जा सकते हैं"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन क्रिप्टोमैट पर बेचे जा सकते हैं"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ग"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन प्रदर्शित करना"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किसी विशिष्ट उपयोगकर्ता के लिए"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता यूआईडी"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम - यह क्रिप्टो-संपत्ति का पूरा नाम है, जैसा कि इसे सभी देखेंगे: खुद इमिटर, खरीदार, विक्रेता, प्रोसेसिंग आदि। स्ट्रिंग की लंबाई 40 वर्णों से अधिक नहीं होनी चाहिए"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकर - यह क्रिप्टो-संपत्ति का संक्षिप्त नाम है, 3 से 10 वर्णों की संक्षिप्त रूप"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी चुनें जो आपकी क्रिप्टो-संपत्ति के लिए सबसे उपयुक्त है"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समर्थित प्रारूप: पीएनजी, जेपीजी, जेपीईजी, एसवीजी। \nफ़ाइल का आकार 1 एमबी, 500*500 पिक्सेल से अधिक नहीं"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्णन करें कि इमिटर इस क्रिप्टो-संपत्ति को क्यों जारी कर रहा है, यह किस क्षेत्र में काम करेगा या करेगा, इसका मिशन क्या है"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोगो:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उद्देश्य/मिशन/उद्देश्य:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमीशन:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति यूनिट लागत:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि के लिए दशमलव के बाद के अंक। उदाहरण के लिए, अधिकांश फिएट मुद्राओं की सटीकता, जैसे अमेरिकी डॉलर या यूरो, 2 है।"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी की जाने वाली क्रिप्टो-संपत्ति की इकाइयों की संख्या। इमीशन राशि निर्माण संचालन के पूरा होने के तुरंत बाद आपके खाते में उपलब्ध होगी।"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति यूनिट घोषित लागत:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दायित्व वहन करने वाला व्यक्ति:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति जारी करने का निर्णय:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशनों को ब्लॉकचेन में निर्यात करें:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन प्रकार:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति के साथ ऑपरेशनों को सक्षम करें:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध/प्रतिबंध:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस सूची में शामिल देशों के नागरिकों या कर निवासियों को अपने व्यापार में क्रिप्टो-संपत्ति के उपयोग की अनुमति नहीं दें:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य प्रतिबंध और शर्तें:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होस्टिंग का पता:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफेद पुस्तक का पता:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेलीग्राम:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्विटर:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फेसबुक:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य संपर्क:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले पर वापस जाएं"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आगे बढ़ें"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति बनाएं"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम और उद्देश्य"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वित्तीय विशेषताएँ"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमिटर"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में निर्यात"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमाएँ और प्रतिबंध"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्धारित नहीं"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिएट मुद्राओं और अन्य संपत्तियों के लिए स्थिर निर्धारण"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इमिटर (क्रिप्टो-संपत्ति जारी करने वाला व्यक्ति)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफरेंट (क्रिप्टो-संपत्ति वितरित करने वाला व्यक्ति)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने वाला"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं (प्रायोजन को सक्षम करें)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति का प्लेसमेंट"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति का अर्थ है मूल्य या अधिकार का डिजिटल प्रतिनिधित्व, जो ब्लॉकचेन तकनीक का उपयोग करके इलेक्ट्रॉनिक रूप में प्रेषित और सहेजा जा सकता है। क्रिप्टो-संपत्तियों में फिएट मुद्राएँ, प्रतिभूतियाँ और अन्य वित्तीय साधन और संपत्तियाँ शामिल नहीं हैं, जो अलग से सरकारी विनियमन के अधीन हैं।"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो परिसंपत्ति संपादित करें"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रशासन"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार करने से इंकार करने का कारण"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक का खाता"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाध्यकारी मुद्रा में प्रस्तावक का खाता"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक प्राथमिक बाजार पर खरीद/बिक्री लेनदेन में परिसंपत्ति के जारीकर्ता का प्रतिनिधि है। \nखाता निष्क्रिय है. \nएक परिसंपत्ति निर्गम खाता हो सकता है"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता निष्क्रिय है, मुद्रा में जुड़ा हुआ है। \nयह पिछले खाते के समान उपयोगकर्ता का होना चाहिए"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं को एटीएम पर संपत्ति खरीदने की अनुमति दें"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आफ्टरमार्केट खरीद फॉर्म"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक बाज़ार लेनदेन गेटवे के माध्यम से किए जाते हैं"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आफ्टरमार्केट सेल्स फॉर्म"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वितीयक बाज़ार लेनदेन गेटवे के माध्यम से किए जाते हैं"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सचेंज पर किसी परिसंपत्ति के साथ लेनदेन की अनुमति दें"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पी2पी के माध्यम से परिसंपत्ति लेनदेन की अनुमति दें"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप एक व्यक्तिगत उद्यमी या कानूनी इकाई के प्रतिनिधि हैं, तो आपको पीडीएफ प्रारूप में डिजिटल संपत्ति जारी करने पर निर्णय फ़ाइल डाउनलोड करनी होगी। \nसमाधान में आभासी संपत्ति की सभी मुख्य विशेषताएं शामिल होनी चाहिए और व्यक्तिगत उद्यमी या इस कानूनी इकाई के इलेक्ट्रॉनिक हस्ताक्षर के साथ हस्ताक्षरित होना चाहिए। \nडिजिटल परिसंपत्ति जारी करने के निर्णय का एक उदाहरण हमारी वेबसाइट के पृष्ठों पर \"दस्तावेज़\" अनुभाग में पाया जा सकता है"])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक आभासी संपत्ति रखना"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानापन्न निर्गम खाता"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारीकर्ता खातों में से चयन करें"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आभासी संपत्ति"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना सेटिंग"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्षम करें"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशनों के बारे में सूचित करें"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रमाणीकरण (व्यक्तिगत कैबिनेट, मोबाइल एप्लिकेशन आदि में प्रवेश)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"कार्ड के माध्यम से वित्तीय ऑपरेशनों का निष्पादन\" घटना पर सूचित करें"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खर्च (कोई भी भुगतान, ट्रांसफर, सेवा भुगतान आदि)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकदी की प्राप्ति"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक मेल द्वारा पत्र की प्राप्ति"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान के लिए बिल की प्राप्ति"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुरक्षा सेटिंग्स का परिवर्तन"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा ऑपरेशनों की पुष्टि करें"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा प्रमाणीकरण की पुष्टि करें"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"कार्ड के माध्यम से वित्तीय ऑपरेशनों का निष्पादन\" की घटना पर पुष्टि करें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा सुरक्षा सेटिंग्स के परिवर्तन की पुष्टि करें"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश से कोड द्वारा खर्च की पुष्टि करें"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन सहेजे नहीं गए, सहेजें?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें नहीं"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्ति तिथि"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रसंस्करण तिथि"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतानों की संख्या"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल सहित"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटियों सहित"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा प्रदाता"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहला पैरामीटर"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान नहीं मिले"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक साथ कई भुगतान कर सकते हैं। इसके लिए आपको पहले एक फ़ाइल बनानी होगी जिसमें भुगतान का विवरण हो, फॉर्मेट \"XLS\", \"XLSX\", \"CSV\" या \"XML\" में।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल का विश्लेषण"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क व्यक्ति"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैरामीटर"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल की जानकारी"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल भुगतान: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल राशि: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान करें"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल अपलोड करें"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान और ट्रांसफर"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामूहिक भुगतान सेवा अन्य उपयोगकर्ताओं को तेजी से धन हस्तांतरण करने और सेवा प्रदाताओं को भुगतान करने की अनुमति देती है।"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विभाग में"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फ़ाइल अपलोड करें"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक्सेल (XLS, XLSX, CSV) या XML फॉर्मेट में फ़ाइल अपलोड कर सकते हैं।"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य सूची"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहले से बने सामूहिक भुगतान अनुरोधों की स्थिति देखें।"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक्सेल स्प्रेडशीट संपादक में फ़ाइल बना सकते हैं।"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल का उदाहरण डाउनलोड करें"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण नोट:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल फॉर्मेट - CSV, XLS या XLSX।"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल बिना हेडर के होनी चाहिए।"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबी संख्याएँ एक्सपोनेंशियल फॉर्मेट में बदलने से बचाने के लिए, आप संख्याओं के आगे एकल उद्धरण (') जोड़ सकते हैं या डेटा दर्ज करने से पहले सेल को टेक्स्ट फॉर्मेट में सेट कर सकते हैं।"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा प्रदाताओं को भुगतान के लिए कॉलम फॉर्मेट"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल में कॉलम की सूची:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ऑपरेटर की सूची से निर्दिष्ट करें, जो यहां दी गई है"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहाँ"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (सभी मोबाइल ऑपरेटरों के लिए, आप ऑपरेटर कोड के रूप में 7000 निर्दिष्ट कर सकते हैं - इस मामले में, ऑपरेटर फोन नंबर द्वारा स्वचालित रूप से निर्धारित किया जाएगा।)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि उस संपत्ति के टिकर में निर्दिष्ट की जाती है जिससे हस्तांतरण किया जा रहा है। यदि ऐसा टिकर नहीं है, तो प्रणाली के रूपांतरण गुणांक को स्वचालित रूप से लागू किया जाएगा।"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की टिप्पणी"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". उस पैरामीटर का मूल्य निर्दिष्ट करें जिसकी ऑपरेटर को आवश्यकता है, उदाहरण के लिए, फोन नंबर। कई ऑपरेटरों के लिए एक भुगतान पैरामीटर पर्याप्त होता है, लेकिन अगर पैरामीटर की संख्या अधिक है, तो आपको उन्हें पहले कॉलम के बगल में निर्दिष्ट करना होगा।"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप किसी ऑपरेटर का भुगतान करते समय पैरामीटर देख सकते हैं:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ताओं के लिए सामूहिक हस्तांतरण के लिए कॉलम फॉर्मेट"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". 0 निर्दिष्ट करें या इसे बिल्कुल भी निर्दिष्ट न करें।"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि खाते के टिकर में प्रदर्शित की जाती है जिससे संपत्ति हस्तांतरित की जा रही है। यदि ऐसा टिकर नहीं है, तो प्रणाली के गुणांक को स्वचालित रूप से लागू किया जाएगा।"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की टिप्पणी"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता खाता"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यहां उपयोगकर्ता खाते की संख्या निर्दिष्ट करें जिसे भुगतान करना है (20 अंक)।"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट एजेंट द्वारा ऋण जारी करने के लिए कॉलम फॉर्मेट"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यह पंक्ति इंगित करती है कि भुगतान ऋण है।"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". राशि क्रेडिट उत्पाद की मुद्रा में निर्दिष्ट की जाती है।"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट टिप्पणी"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". वैकल्पिक भरें, इसे खाली छोड़ा जा सकता है; लंबाई 255 वर्णों से अधिक नहीं होनी चाहिए।"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऋणकर्ता आईडी"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". कृपया ध्यान दें कि ऋणकर्ता के पास Mfo-BorrowPerson डिजिटल पासपोर्ट होना चाहिए।"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट उत्पाद कोड"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". इसे हाथ से ऋण जारी करते समय क्रेडिट उत्पाद सूची में देखा जा सकता है।"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". केवल अवधि की संख्या (कौन सी अवधि उपयोग की जाती है क्रेडिट उत्पाद में निर्दिष्ट है, उदाहरण के लिए, यह दिन, सप्ताह, महीने आदि हो सकते हैं।)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर कोड (फॉर्म नंबर)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". यह और निम्नलिखित फ़ील्ड भरना आवश्यक नहीं है, यदि आप उन्हें नहीं भरते हैं, तो ऋणकर्ता बाद में अपने व्यक्तिगत कैबिनेट के माध्यम से स्वयं भुगतान निकाल सकता है।"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि की स्थिति में रद्द करें"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (1) त्रुटि के साथ समाप्त होने वाले लेन-देन के मामले में जारी किए गए ऋण को रद्द करें, या (0) इसे बाद में निकालने के लिए छोड़ दें।"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". उस पैरामीटर का मूल्य निर्दिष्ट करें जिसकी ऑपरेटर को आवश्यकता है, उदाहरण के लिए, फोन नंबर। कई ऑपरेटरों के लिए एक भुगतान पैरामीटर पर्याप्त होता है, लेकिन अगर पैरामीटर की संख्या अधिक है, तो आपको उन्हें पहले कॉलम के बगल में निर्दिष्ट करना होगा।"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान पैरामीटर №"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम संदेश"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई अनुरोध नहीं"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राथमिकता"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विषय"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश का पाठ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक या अधिक फाइलें अपलोड कर सकते हैं। फाइल का अधिकतम आकार - 10MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निम्न"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तकनीकी सहायता"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय अनुरोध"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया अनुरोध"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर द्वारा प्रोसेस हो रहा है"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बनाया गया"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["काम चल रहा है, ऑपरेटर के उत्तर की प्रतीक्षा करें"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बंद"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोध बंद"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नए संदेश नहीं"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर से नया संदेश"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नए संदेश नहीं"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह में भेजें"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्प्लेट्स"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुनः पूर्ति"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निकासी"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेटर"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षेत्र"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई टेम्पलेट नहीं"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई टेम्पलेट № को हटाना चाहते हैं?"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम बदलें"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम बदलें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटाएं"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग करें"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी की जानकारी"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी / खाता / ईमेल / फोन"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी / क्रिप्टो-पता / ईमेल / फोन"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य जानकारी"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की जानकारी"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंट्रोल सिग्नेचर"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे स्टोर"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई स्टोर नहीं मिला"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विजेट कोड बनाएं"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक बनाएं"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर हटाएं"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप वाकई स्टोर को हटाना चाहते हैं?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण करें"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान मोड"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकृति"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर पंजीकरण करें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिस्टम में पंजीकृत इलेक्ट्रॉनिक स्टोर ग्राहक से भुगतान प्राप्त कर सकते हैं।"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर के रूप में इलेक्ट्रॉनिक स्टोर, एक्सचेंज प्वाइंट और अन्य इंटरनेट संसाधन पंजीकृत होते हैं, जो भुगतान स्वीकार करना चाहते हैं।"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम या ट्रेडमार्क"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर का URL पता"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर का संक्षिप्त विवरण"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेवा का प्रकार"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर सक्रिय है"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुप्त कुंजी"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वचालित भुगतान की अनुमति दें"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम को स्क्रिप्ट पर भेजें"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्क्रिप्ट का पता"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल भुगतान के बाद वापसी"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असफल भुगतान के बाद वापसी"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जेनरेट करें"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम से कम 30 अक्षर। अनुमत प्रतीक: लैटिन अक्षर, अंक"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान प्रणाली द्वारा भेजे गए और प्राप्त डेटा की सत्यता और अखंडता की जाँच करने के लिए उपयोग किया जाता है।"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछे"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर की सूची पर वापस जाएं"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालानों की सूची"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चालान नहीं मिला"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानार्थक"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि भरा गया है, तो घोषणा केवल निर्दिष्ट देशों के उपयोगकर्ताओं के लिए उपलब्ध होगी"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माप की इकाइयाँ"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षेप में"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त जानकारी"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण दिशा"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय (मिनट)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विज्ञापन सक्रिय है"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल लिंक के माध्यम से उपलब्ध"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए उपलब्ध"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले पर वापस जाएं"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित करें"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिबंध"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा बनाना"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी बाहरी संपत्ति"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बांधना नहीं"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य कम करें"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस लौटाएं"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस संपत्ति में कोई खाता नहीं"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य निर्धारित करें"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य सही है"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य कम करें"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस लौटाएं"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम अधिकतम से अधिक है"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कहां और कैसे आप स्थानांतरण प्राप्त करना चाहते हैं। उदाहरण के लिए: बैंक ऐप में फोन नंबर +919990001111 (प्राप्तकर्ता अलेक्जेंडर एन.) द्वारा स्थानांतरण करें"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वांछित विवरण"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेकर को कौन से विवरण भेजने होंगे ताकि आप उन्हें स्थानांतरण कर सकें"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का चयन करें"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का चयन करें"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप क्या प्राप्त करना चाहते हैं: आंतरिक संपत्ति में धन, जैसे बीटीसी या बाहरी संपत्ति में, जैसे टोकन गोल्ड?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया इस लेनदेन की विशेषताओं पर टिप्पणी लिखें, जैसे: \\\"बाहरी संपत्ति केवल व्यक्तिगत मुलाकात पर ही हस्तांतरित की जाती है\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य के प्रकार, जो बाहरी संपत्ति से संबंधित है, जैसे \\\"नकद पैसा\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्लेटफ़ॉर्म पर प्रदर्शन के लिए संपत्ति का नाम"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति को सूची के शब्दों से भी बुलाया जा सकता है"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा संपत्ति के साथ तुलना"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए: लीटर, टुकड़े, घंटे, बक्से"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उदाहरण के लिए: ल, टु, घं, बक"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति के मूल्यों में दशमलव के बाद कितनी संख्या हो सकती है"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने अभी तक मौजूद नहीं संपत्ति को बनाने का चयन किया है, कृपया इसकी विशेषताएँ भरें"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तों को पूरा करने के लिए पक्षों को दिया गया समय"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति वह संपत्ति है जो \\\"न्यू रियलिटी\\\" प्रणाली में मौजूद है। बाहरी संपत्ति वह संपत्ति है जो \\\"न्यू रियलिटी\\\" प्रणाली में मौजूद नहीं है।"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाध्य नहीं"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारण"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा रद्द करें"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे में भागीदारी की पुष्टि करें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे से इंकार करें"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय बढ़ाएं"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा रद्द करना"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे में उपयोगकर्ता की स्थिति निर्धारित नहीं है। कुछ गलत हो गया"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता का अनुरोध करें"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता पर सहमत हों"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण की सूचना दें"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण प्राप्ति की पुष्टि करें"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का मूल्यांकन करें"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यांकन भेजें"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा №"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने के लिए"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने के लिए"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमा"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदों"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदा"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सौदा नहीं"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभ"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का कोड"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई सक्रिय सौदे नहीं"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे सौदे"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति का विवरण"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई घोषणा नहीं"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी घोषणाएं"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी घोषणाएं"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नई घोषणा"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिशा"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्ति"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दे रहा हूँ"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त कर रहा हूँ"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सीमाएँ"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशन तिथि"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते में शेष"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का अनुरोध करें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्ताव"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय सीमा"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पार्टियों की क्रियाओं की प्रतीक्षा का समय"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे का अनुरोध करें"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता बनाएं"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता चयनित नहीं"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि सीमा में नहीं है: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिनट"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देने के लिए"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करने के लिए"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देता हूँ"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त करता हूँ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक संपत्तियाँ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्तियाँ नहीं मिलीं"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्तियाँ"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयनित:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आंतरिक"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्यस्थता का अनुरोध करें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्तियाँ"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माप की इकाइयाँ"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाने की तारीख"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखक"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि से"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवधि तक"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संपत्ति नहीं मिली"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ष"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखक"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि सीमा"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक्षा समय (मिनट)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घोषणा सक्रिय है"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल लिंक के माध्यम से उपलब्ध"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल सत्यापित उपयोगकर्ताओं के लिए उपलब्ध"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संपत्ति संपादित करें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समूह"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समानार्थक"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपत्ति से बंधा हुआ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माप की इकाइयाँ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संक्षेप में"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सटीकता"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रारंभ"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी चुनें"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूरी स्क्रीन पर तालिका को विस्तृत करने के लिए क्लिक करें"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपडीलर"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक एटीएम बनाएं"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमाट संपादित करें"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य जानकारी"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुलने का समय"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगिन"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना स्थान का विवरण"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मानक"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमानक"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य का मोड"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सोमवार"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मंगलवार"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुधवार"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गुरुवार"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुक्रवार"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शनिवार"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रविवार"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोपहर का भोजन"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड पुनः दर्ज करें"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा खातों का उपयोग करें"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए खाते बनाएं"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य जानकारी"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य समय (संदर्भ जानकारी)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई क्रिप्टो मशीन नहीं मिली"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम नंबर"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षांश"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देशान्तर"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हेल्पलाइन"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उप-डीलर आईडी"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिसाब किताब"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जाँच करना"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पारगमन खाता"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमैट का निर्माण"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टोमैट का संपादन"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निषेध"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों की सूची"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संग्राहक जोड़ें"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह शेष पर जाने में असमर्थ - कोई डेटा नहीं"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों की सूची"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक संग्राहक जोड़ें"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमत"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्वीकार"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संपादित करें"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान संग्रह शेष देखें"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनलोड किए गए संग्रह शेष देखें"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कोड"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप कोई सबडीलर निर्दिष्ट नहीं करते हैं, तो एटीएम आपसे लिंक कर दिया जाएगा"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षांश और देशांतर भौगोलिक निर्देशांक हैं जिन्हें कॉपी किया जा सकता है, उदाहरण के लिए, एटीएम पता दर्ज करके Google मानचित्र से"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थापना स्थान"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तालिका प्रदर्शित करने के लिए कम से कम एक कॉलम का चयन किया जाना चाहिए"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाना"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉनिक चेक"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक बनाएं"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाया गया"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रृंखला"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएँ"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई चेक नहीं"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय करें"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉनिक चेक निर्माण"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन खाता"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रत्येक चेक का अंकित मूल्य"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक की संख्या"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाएं"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रृंखला:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोड:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चेक बनाए गए"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्यात"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉनिक चेक सक्रियता"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट खाता"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रृंखला"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रियता कोड"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय करें"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उफ़, कोई त्रुटि हुई!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संग्रह"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह जोड़ें"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट करें"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुरालेख संग्रह न दिखाएं"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द किए गए संग्रह न दिखाएं"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिंदु संख्या"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई संग्रह नहीं"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण आवेदन प्रस्तुत किया गया"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह संपादित करें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण स्थिति बदलें"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह जोड़ा जा रहा है"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल जानकारी"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल बातें"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दवासाज़"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिंदु संख्या"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति आवेदन कार्डों की संख्या"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नकद संग्राहक"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्राहकों के लिए नोट"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह पर टिप्पणी"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑर्डर से बाहर लोड हो रहा है"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता कैसेट बदलें"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्डों पर टिप्पणी"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिजेक्ट कार्ड डिस्पेंसर कैसेट से सभी कार्ड हटा दें"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्यूब से सभी कार्ड निकालें"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्वाइंट नंबर एक आवश्यक फ़ील्ड है जिसे भरना होगा"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में बिलों की संख्या 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में राशि 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट में बिलों की संख्या 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेप 1 पर टिप्पणी"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाना"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादन संग्रह"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट नंबर"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट आईडी"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट संग्रह संख्या"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट रेटिंग"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड किए गए"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वास्तव में"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट पर टिप्पणी"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कोई डेटा नहीं"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपकरण संग्रह"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सी"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वारा"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्वर"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिलों की संख्या"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दवासाज़"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अस्वीकार/वापस लेना"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिल स्वीकर्ता"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड अस्वीकार करें"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्ड"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मौजूदा"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उतार"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम संग्रह शेष"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपकरण संग्रह"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम में कैसेट लगाया गया"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब्त"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अवशेषों को ले जाया जाता है"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाली कैसेट:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आसंजन:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त जानकारी:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख तक"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति चुनें"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वसूली हेतु आवेदन प्रस्तुत कर दिया गया है"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वसूली आवेदन रद्द कर दिया गया"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्पादन के लिए संग्रहण स्वीकृत"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रहण खजांची द्वारा किया जाता है (पैसा कैसेट में लोड किया जाता है)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैसेट संग्राहकों को सौंप दिए गए (रास्ते में पैसा)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम पर कैश कलेक्शन स्थापित किया गया"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक विफलता के परिणामस्वरूप, दूसरे संग्रह से अतिभारित हो गया"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम में संग्रह को दूसरे संग्रह से बदल दिया गया (रास्ते में रहता है)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संग्रह शेष पोस्ट किए जाते हैं (संग्रह संग्रह में भेजा जाता है)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्रवाई"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कलेक्टर के बारे में जानकारी संपादित करें"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करना"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बचाना"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रस्तावक के खाते का चयन"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रपत्र फ़ील्ड संख्या"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवश्यक आईडी गायब है"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपने सफलतापूर्वक लॉग इन कर लिया है, एटीएम पर परिचालन जारी रखें"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एटीएम से लेनदेन जारी रखें"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदर्शित करने के लिए कॉलम चुनें"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिपोर्ट बनाएं"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नमूना"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चादर"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत खाता"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापार खाता"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य नाम: (यदि है)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न और अपोस्ट्रॉफ अनुमत हैं।"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न, स्पेस और अपोस्ट्रॉफ अनुमत हैं।"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नहीं"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर, हाइफ़न, उद्धरण, स्पेस, अंक और अपोस्ट्रॉफ अनुमत हैं।"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फोन"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लैटिन अक्षर (अपरकेस और लोअरकेस), अंक, प्रतीक _ - . ! $ % ^ * = अनुमत हैं"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड दोहराएं"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड मेल नहीं खाते"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता जानकारी"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सही ईमेल पता या फोन नंबर दर्ज करें। फोन नंबर अंतर्राष्ट्रीय प्रारूप में दर्ज करें, उदाहरण: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप सफलतापूर्वक पंजीकृत हैं"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अब आप कर सकते हैं"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यक्तिगत खाते में लॉग इन करें"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका खाता हमारे कर्मचारी द्वारा जाँच के अधीन है, हम आपको सक्रियता के परिणामों की सूचना देंगे।"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण की पुष्टि के लिए लिंक के साथ ईमेल भेजा गया है"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके ईमेल पते पर"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहली लॉगिन के बाद आपको फोन नंबर की पुष्टि करनी होगी"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्तकर्ता:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता नहीं मिला"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विस्तृत जानकारी"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता चयन"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य पैरामीटर"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खातों में शेष परिवर्तन"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन के अंत में:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन की शुरुआत में:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त पैरामीटर"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में ऑपरेशन्स"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉलबैक"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला प्रयास:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्तर"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सौदे की प्रोटेक्शन"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन अवधि:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तक"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोटेक्शन कोड:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि करें"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट पैरामीटर"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट का नाम:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन रद्द करें"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन दोहराएं"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट सहेजें"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन संख्या"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिपक्षी"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की तारीख"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान फॉर्म संख्या"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आमद/खर्च"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते की शेष राशि में परिवर्तन"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा की पुष्टि करें"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषक को पैसे वापस करें"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदिग्ध ऑपरेशन"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाओं के विकल्प:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता नहीं मिला"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चयन करें"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण पासवर्ड दर्ज करें"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन दोहराएं"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन इतिहास"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खातों की सूची"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात त्रुटि"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट्स"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरी संपत्ति"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नए संपत्ति की जानकारी"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त इमिशन"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्चुअल संपत्ति की सूची"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन से लिंक करें"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनपुट:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता चुनें"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवास स्थान"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षेत्र"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जिला"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["काउंटी"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शहर"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शहरी क्षेत्र"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निवास स्थान"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सड़क"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पते का अतिरिक्त तत्व"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मकान"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पोस्टल कोड"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तकनीकी सहायता अनुरोध"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैट"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश जोड़ें"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप एक या अधिक फाइलें अपलोड कर सकते हैं। फाइल का अधिकतम आकार - 10MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश का पाठ"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजें"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के लिए विजेट निर्माण"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जेनरेट करें"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["में भुगतान"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की राशि"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता पर क्रियाएँ"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असफलता पर क्रियाएँ"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान कोड / लिंक जेनरेट करना"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर ऑपरेशन संख्या"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त पैरामीटर"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचना URL"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफल भुगतान के बाद ग्राहक को लौटाने का URL"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रुटि के बाद ग्राहक को लौटाने का URL"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान परिणामों की सूचना भेजने का URL"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि तय करें"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉपी करें"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जांचें"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान लिंक"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फॉर्म"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रलेखन"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान खाते की जीवन अवधि (मिनटों में), डिफ़ॉल्ट 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक की वैधता अवधि"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिंक"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फॉर्म"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विजेट"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टोर संपादित करें"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप इसे देखते हैं, तो फॉर्म प्राप्त नहीं हुआ है (लेकिन आपको यह नहीं देखना चाहिए)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त इमिशन"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो-संपत्ति"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाता - कवरेज का स्रोत"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कमीशन डेबिट खाता"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अतिरिक्त मुद्दा"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जारी रखें"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान अंक का आकार"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टोकन"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कलई करना"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कवरेज गणना"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंधन:"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिडक्शन"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जानकारी"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल चुनें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमत प्रारूप:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात प्रारूप"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल का आकार बहुत बड़ा है, अधिकतम आकार:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइल अपलोड करने के लिए चुनें या खींचें"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपलोड करें"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें अपलोड की गईं"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फाइलें"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मिटाना"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति अपडेट करें"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगी लिंक"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य सुरक्षा सेटिंग्स"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरण आवश्यक है"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राधिकरण को एक-बार कुंजी से पुष्टि करनी होगी"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राधिकरण को जादुई शब्द से पुष्टि करनी होगी"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण को एसएमएस संदेश से कोड द्वारा पुष्टि करनी होगी"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से स्ट्रिंग"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तक"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अक्षर"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमान्य मान"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कौन से कॉलम प्रदर्शित करने हैं यह चुनने के लिए क्लिक करें"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिप्टो आईडी:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुभ प्रभात"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुभ दोपहर"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुभ संध्या"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुभ रात्रि"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉग आउट"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिखा रहे हैं:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति पृष्ठ"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिखाएँ"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की पुष्टि करें"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी शुल्क की गणना करें"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान वापस करें"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ब्लॉकचेन में हैश"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एएमएल जोखिम"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्क"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा करने के लिए"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि आप संशोधित शर्तों से सहमत हैं, तो जमा की पुष्टि करें:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि ये शर्तें आपके लिए उपयुक्त नहीं हैं, तो आप भुगतान को वापस प्रेषक खाते में भेज सकते हैं:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एएमएल जोखिम है"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसलिए जमा के लिए पुनर्गणना की गई शुल्क"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस प्रकार जमा राशि है"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नई प्राप्तियां नहीं मिलीं"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की तारीख"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस"])}
      }
    }
  }
}