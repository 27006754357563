export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Český"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizace"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomněli jste heslo?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste zde poprvé?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon nebo e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostřední jméno:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narození:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najít"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel nalezen"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry/Komentář"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné operace nenalezeny"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet operací"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ks na částku"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy operací"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby, platba za služby"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní převody"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba účtu"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody mezi vlastními účty"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření šeku v kryptoměně"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivujte šek v kryptoměně"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy/výdaje"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmy"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= rovná se"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ nerovná se"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> větší"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ větší nebo rovný"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menší"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menší nebo rovný"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně dokončeno"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie operací"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění účtu"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směna na burze"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění adresy"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody uvnitř vlastního peněženky"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitální pas"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelské ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum registrace:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední autorizace:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vše"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnit"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné účty"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzovací heslo"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro potvrzení operací musíte vytvořit potvrzovací heslo."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populární platby"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinky"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dále..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrační údaje"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzeno"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veřejně dostupné"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najít"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve skupině"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny plateb"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka platby"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet převodu"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplněný účet"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění účtu"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o převodu"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat převod"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o převodech"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukce"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění adresy"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplněná adresa"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na seznam úkolů"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa převodu"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat operaci"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie operací"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam účtů"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámá chyba"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam tokenů"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet převodu:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana transakce"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ochrany:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnů"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit jako šablonu"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převést"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod jinému uživateli"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochranný kód - kód, který musí příjemce zadat pro dokončení transakce. Ochráněný převod příjemce uvidí ihned v sekci \"Historie operací\", ale prostředky bude moci použít až po zadání ochranného kódu v kartě transakce. Pokud platnost platby vyprší, prostředky se vrátí odesílateli."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa převodu"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkud"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kam"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převést"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody mezi vlastními účty"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převody uvnitř peněženky"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstatek"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstup"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstup"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit novou adresu"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový účet"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit vybrané"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam účtů"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název účtu"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit účty?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit účet"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva nenalezena"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné účty nenalezeny"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenování adresy"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit adresy?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete odstranit adresu"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ adresy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoměny"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit adresu"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření nové adresy"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření nového účtu"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnost"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení ochrany proti phishingu je dodatečný mechanismus ochrany při práci s účtem."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vámi nastavená uvítací fráze se bude zobrazovat v horní části každé stránky účtu."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správná fráze vám ukáže, že jste na správné stránce, nesprávná fráze je signálem, že je třeba okamžitě kontaktovat bezpečnostní službu našeho systému, aniž byste prováděli jakékoliv operace."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnout ochranu proti phishingu:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochranná fráze proti phishingu:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana proti phishingu"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny vstoupí v platnost po další autorizaci"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení přístupu podle IP adresy vám (nebo útočníkovi) neumožní pracovat se systémem z adresy, která není uvedena v seznamu povolených."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení přístupu k účtu podle IP adres"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat adresy"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adres: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam adres"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresy"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresy"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné adresy nenalezeny"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázat"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte IP adresu"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP adresa je nesprávná"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není zadána žádná IP adresa nebo blok adres."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná IP adresa není povolena pro autorizaci. Nebudete mít přístup ke svému účtu."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup podle IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelná slova jsou dodatečné bezpečnostní nastavení."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je kouzelné slovo zapnuto, při každé autorizaci vás systém požádá o zadání některých symbolů (podle jejich pořadí) z kouzelného slova."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadovat kouzelné slovo:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do účtu:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při platbě přes obchodníka:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při použití karty:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelné slovo"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče výrazně zvyšují bezpečnost systému."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přihlášení do programu budete požádáni nejen o zadání přihlašovacího jména a hesla, ale také o zadání jednorázového klíče. Aktivace probíhá postupně. Poslední klíč je platný, dokud nevytvoříte novou sérii klíčů."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít jednorázové klíče"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam klíčů"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generovat"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["č."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klíč"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorázové klíče"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["''Nové heslo'' a ''Potvrzení hesla'' se neshodují."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna hesla"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řetězec od 4 do 63 znaků. Povolené znaky jsou latinské písmena, číslice a symboly"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["''Nové heslo'' a ''Potvrzení hesla'' se neshodují."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo nesmí obsahovat azbuku"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit potvrzovací heslo"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit potvrzovací heslo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na hlavní stránku"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řetězec od 4 do 65 znaků. Povolené znaky jsou latinské písmena, číslice a symboly"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V případě, že zapomenete heslo pro přístup k účtu, můžete jej obnovit odpovědí na tajnou otázku."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V této sekci můžete změnit tajnou otázku/odpověď."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajná otázka"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše otázka:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpověď:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajná otázka"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní otázka"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zůstatek "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodní pár:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ směny:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod na trh - bude použit nejvýhodnější kurz v době provedení příkazu. Pozor, částka k přijetí se může změnit a lišit od vypočtené částky v závislosti na příkazech v době směny."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod za pevnou cenu probíhá podle zadané částky a kurzu. Bude zadán příkaz. Pokud se zadaný kurz výrazně liší od tržního, uzavření příkazu může trvat delší dobu."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz (předpoklad):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz není určen, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doporučuje se zadat kurz ručně"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít vše"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatek:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatek:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na trh"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(rychlá směna za nejlepší protinabídku)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pevný kurz "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(zadání příkazu se zadaným kurzem)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou žádné vhodné účty"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna za 24 hodin"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolísání za 24 hodin"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objem obchodů za 24 hodin"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka nesmí být nulová!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směnit"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li zobrazit souhrnné údaje, vyberte obchodní pár"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze vyměňovat stejné aktiva"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burza"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh. Souhrn"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podobné nabídky"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protichůdné nabídky"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávají"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávají"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje objednávky"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední obchody"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáno"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum a čas"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poplatek"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné obchody"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné objednávky"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně dokončeno"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování nezačalo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování začalo"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně zrušeno"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno a zrušeno"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváno"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny objednávky"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny objednávky"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poplatek"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné objednávky"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální částka uvedená pro transakci"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální částka poskytnutá za transakci"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální částka, která má být přijata"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální přijatá částka"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet pokusů o transakci"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převedená kryptoaktiva"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve kterém bude kryptoaktivum přijato"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjecí účet"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připsaný účet"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam subdealerů"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ data není vybrán"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukončení"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Která část je fixní a která proměnná"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixuje se dávkovaná částka"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixuje se přijímaná částka"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ objednávky"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel chce rychle směnit za nejlepší protinabídky"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směna za pevný kurz"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování nezačalo"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování začalo"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně dokončeno"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plně zrušeno"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně dokončeno a zrušeno"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odevzdávané aktivum"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímané aktivum"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ data"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dát částku"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostat částku"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obchodů"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P nabídky"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID prodávajícího"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód nabídky"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivum"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající je ověřen"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze pro ověřené uživatele"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze provést obchod sám se sebou"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat aktivum"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam virtuálních aktiv"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydávání tokenů"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcept"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trh"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primární"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundární"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřazené k jinému aktivu"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobné informace o krypto aktivu"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit data o krypto aktivu"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další problém"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat krypto aktivum"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny lze zakoupit v kryptomatu"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny lze prodávat v kryptomatu"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení tokenů"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro konkrétního uživatele"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID uživatele"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název je plné jméno kryptoaktivy, jak jej uvidí všichni: samotný emitent, kupující, prodejci, procesory atd. Řetězec o délce nejvýše 40 znaků"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiker je krátký název kryptoaktivy, zkrácená zkratka o délce od 3 do 10 znaků"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kategorii, která nejlépe odpovídá vaší kryptoaktivitě"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podporované formáty: png, jpg, jpeg, svg. \nVelikost souboru ne více než 1 MB, 500*500 pixelů"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popište, proč je tato cryptoasset emitována emitentem, v jakém odvětví působí nebo bude působit, jaké je její poslání"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účel / mise / určení:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emise:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota jednotky:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet desetinných míst pro částku. Například přesnost většiny fiat měn, jako je americký dolar nebo euro, je 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vydaných jednotek kryptoakcií.Celková částka emise bude okamžitě k dispozici na vašem účtu po dokončení transakce vytvoření kryptoaktiv."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deklarovaná hodnota jednotky:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajištění:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba nesoucí závazky:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozhodnutí o emisi kryptoaktivity:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat operace do blockchainu:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ blockchainu:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit operace s kryptoaktivitou:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení/Sankce:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte, kteří občané nebo daňoví rezidenti kterých zemí z výše uvedeného seznamu mají zakázáno používat kryptoaktiva"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další omezení a podmínky:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa hostingu:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa bílé knihy:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další kontakty:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit aktivum"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název a účel"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanční charakteristiky"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export do blockchainu"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity a omezení"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurčeno"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixní vazba na fiat měny a další aktiva"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent (Osoba, která vydává kryptoaktivitu)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízející (Osoba, která distribuuje kryptoaktivitu)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesílatel"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já (zapnout sponzorství)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění kryptoaktivity"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoaktivita znamená digitální vyjádření hodnoty nebo práva, které může být přeneseno a uloženo elektronicky pomocí technologie blockchainu. Kryptoaktiva NEzahrnují fiat měny, cenné papíry a jiné finanční nástroje a aktiva, které podléhají zvláštní státní regulaci."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit krypto aktivum"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod odmítnutí přijetí"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet navrhovatele"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet navrhovatele v závazné měně"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrhovatel je zástupcem emitenta aktiva v nákupních/prodejních transakcích na primárním trhu. \nÚčet je pasivní. \nMůže to být účet pro vydání aktiv"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet je pasivní, propojený v měně. \nMusí patřit stejnému uživateli jako předchozí účet"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umožnit uživatelům nákup aktiv v bankomatech"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprodejní formulář nákupu"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na sekundárním trhu se provádějí prostřednictvím bran"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprodejní formulář prodeje"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na sekundárním trhu se provádějí prostřednictvím bran"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit transakce s aktivem na burze"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit transakce aktiv prostřednictvím P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud jste samostatný podnikatel nebo zástupce právnické osoby, musíte si stáhnout soubor rozhodnutí o vydání digitálního majetku ve formátu PDF. \nŘešení musí obsahovat všechny hlavní charakteristiky virtuálního majetku a být podepsáno elektronickým podpisem jednotlivého podnikatele nebo této právnické osoby. \nPříklad rozhodnutí o vydání digitálního aktiva naleznete na stránkách našeho webu v sekci \\\"Dokumenty\\\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění virtuálního majetku"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhradní účet vydání"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte z účtů vydavatelů"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuální aktiva"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení oznámení"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit o operacích"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizace (přihlášení do osobního kabinetu, mobilní aplikace atd.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit při události \"Provedení finančních operací pomocí karty\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje (jakékoli platby, převody, platba služeb atd.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem peněžních prostředků"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí dopisu na vnitřní poštu"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem faktury k úhradě"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna nastavení zabezpečení"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit operace kódem ze zprávy"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit autorizaci kódem ze zprávy"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit při \"Provedení finančních operací pomocí karty\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit změnu nastavení zabezpečení kódem ze zprávy"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit výdaje kódem ze zprávy"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny nebyly uloženy, uložit?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neukládat"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum příjmu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zpracování"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet plateb"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vč. úspěšných"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vč. chybových"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné platby"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytovatel služeb"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. parametr"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby nenalezeny"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete provést několik plateb současně. K tomu je třeba předem vytvořit soubor s popisem plateb ve formátu \"XLS\", \"XLSX\", \"CSV\" nebo \"XML\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza souboru"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o souboru"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem plateb:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v celkové částce:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor plateb"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odepsaný účet"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provést platby"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrání souboru plateb"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné platby a převody"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služba hromadných plateb umožňuje rychle převádět prostředky jiným uživatelům a provádět platby poskytovatelům služeb."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V sekci"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát soubor plateb"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["můžete nahrát soubor ve formátu Excel (XLS, XLSX, CSV) nebo XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam úkolů"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["umožňuje zobrazit stavy dříve vytvořených žádostí o hromadné platby."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete vytvořit soubor v tabulkovém editoru Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout příklad souboru"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důležité poznámky:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát souboru - CSV, XLS nebo XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor musí být bez záhlaví."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby se dlouhá čísla nepřeváděla na exponenciální formát (např. \"5,46546E+15\") a nezkracovaly se vedoucí nuly, můžete před číslem umístit znak jednoduché uvozovky (') nebo před zadáním dat nastavit buňce textový formát."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro platby poskytovatelům služeb"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sloupců v souboru:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se ze seznamu operátorů uvedených"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zde"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Pro všechny mobilní operátory můžete jako kód operátora uvést 7000 - v tomto případě bude operátor určen automaticky podle telefonního čísla.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka je uvedena v aktivu účtu, ze kterého bude převod proveden. Pokud se aktivum účtu, na který jsou aktiva převáděna, liší od aktiva účtu, který je vybrán k provedení převodu, bude pro transakci automaticky použit kurz konverze systému."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k platbě"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se hodnota parametru požadovaného operátorem, např. telefonní číslo. Mnoho operátorů se omezuje na jeden parametr platby, ale pokud je počet parametrů více než jeden, je třeba uvést všechny ve vedlejších sloupcích."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry platby můžete zobrazit přechodem k platbě jakéhokoli operátora:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro hromadné převody uživatelům"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se 0 nebo se vůbec neuvádí."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka je uvedena v aktivu účtu, ze kterého bude převod proveden. Pokud se aktivum účtu, na který jsou aktiva převáděna, liší od aktiva účtu, který je vybrán k provedení převodu, bude pro transakci automaticky použit kurz konverze systému."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k platbě"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet příjemce"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zde se uvádí číslo účtu uživatele, kterému je třeba provést převod (20 číslic)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát sloupců pro hromadné vydávání úvěrů úvěrovým agentem"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Tento řádek uvádí, že platby představují úvěry."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Částka se uvádí ve měně úvěrového produktu."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k úvěru"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění není povinné, může být prázdné; délka nesmí přesáhnout 255 znaků."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dlužníka"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Upozorňujeme, že dlužník musí mít digitální pas Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód úvěrového produktu"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Lze ho zobrazit v seznamu úvěrových produktů při ručním poskytování úvěru."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Prosté číslo období (jaká období se používají je určeno v úvěrovém produktu, mohou to být např. dny, týdny, měsíce atd.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód operátora (číslo formuláře)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Vyplnění tohoto a následujících polí není povinné, pokud je nevyplníte, může dlužník později samostatně provést výběr úvěru prostřednictvím svého osobního kabinetu."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit v případě chyby"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zrušit (1) poskytnutý úvěr, pokud transakce výběru skončí chybou, nebo ponechat pro následný samostatný výběr (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Uvádí se hodnota parametru požadovaného operátorem, např. telefonní číslo. Mnoho operátorů se omezuje na jeden parametr platby, ale pokud je počet parametrů více než jeden, je třeba uvést všechny ve vedlejších sloupcích."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr platby N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téma"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední zpráva"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné žádosti"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorita"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téma"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text zprávy"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete nahrát jeden nebo více souborů. Maximální velikost souboru je 10 MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nízká"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normální"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysoká"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní žádosti"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová žádost"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracovává se operátorem"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost vytvořena"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá práce, čekejte na odpověď od operátora"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost uzavřena"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost uzavřena"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nové zprávy"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová zpráva od operátora"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nové zprávy"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat do archivu"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnění"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operátor"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné šablony"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat šablonu č."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat šablonu"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o protistraně"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Účet / E-mail / Telefon"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Krypto-adresa / E-mail / Telefon"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o platbě"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolní podpis"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje obchody"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchody nenalezeny"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit widget kód"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit platební odkaz"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat obchod"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat obchod?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Režim plateb"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrovat obchod"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-shop registrovaný v systému může přijímat platby od zákazníků prostřednictvím obchodníka našeho systému."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronické obchody, směnárny a jakékoli jiné online zdroje, které jsou ochotny přijímat platby na svou adresu, jsou registrovány jako obchod."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nebo obchodní značka"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa obchodu"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krátký popis obchodu"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ služby"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod je aktivní"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit automatické výplaty"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posílat výsledky na skript"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa skriptu"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat po úspěšné platbě"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NEúspěšné platbě"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejméně 30 znaků. Povoleny jsou latinské písmena, číslice"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá se k ověření integrity a pravosti dat přenášených platebním systémem (při odesílání uživatele obchodem na obchodníka) a obchodem (při přijímání výsledků platby)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do seznamu obchodů"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam faktur"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktury nenalezeny"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktivum"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyma"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je vyplněno, nabídka bude dostupná pouze uživatelům z uvedených zemí"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázat na aktivum"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkráceně"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr převodu"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama je aktivní"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze po odkazu"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze ověřeným uživatelům"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístit"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezení"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření nabídky"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní externí aktivum"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřipoutávat"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snížit kurz"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit zpět"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neexistuje účet v takovém aktivu"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte kurz"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz je správný"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snížit kurz"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit zpět"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum je větší než maximum"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kam a jak chcete obdržet převod. Například: Převést v bankovní aplikaci na telefonní číslo +499990001111 (příjemce Alexander N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přenáším"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjem"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadované údaje"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaké údaje musí taker zaslat, abyste mu mohli provést převod"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte aktivum"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte aktivum"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co chcete získat: prostředky ve vnitřním aktivu, například BTC, nebo ve vnějším, například TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napište komentář o vlastnostech této transakce, například: \\\"Vnější aktivum je předáváno pouze při osobním setkání\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh hodnot, ke kterým patří vnější aktivum, například \\\"Hotovost\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název aktiva pro zobrazení na platformě"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivum může být také nazýváno slovy ze seznamu"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnání s existujícím aktivem"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například: litry, kusy, hodiny, krabice"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například: l, ks, h, kr"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolik čísel za desetinnou čárkou může mít hodnota aktiva"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrali jste vytvoření aktiva, které ještě nebylo vytvořeno, prosím, vyplňte jeho charakteristiky"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas, který mají strany na splnění podmínek"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní aktivum je aktivum, které existuje v systému \\\"New Reality\\\". Vnější aktivum je aktivum, které neexistuje v systému \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není přiřazen"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit obchod"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit účast v obchodu"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout obchod"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodloužit čekací dobu"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení obchodu"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status uživatele v obchodu není určen. Něco se pokazilo"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o Arbitráž"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasit s Arbitráží"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámit provedení převodu"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit přijetí převodu"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte obchod"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat hodnocení"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod č."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnes"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zítra"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostat"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáváte"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváte"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obchodů"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchod"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné obchody"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídky"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhoda"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód obchodu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávám"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné aktivní obchody"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje obchody"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnitřní aktivum"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis aktiva"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné nabídky"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje nabídky"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje nabídky"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová nabídka"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktivum"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Interní aktivum"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyměňuji"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávám"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zveřejnění"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstatek na účtu"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadavek obchodu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávající"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba akcí stran"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáváte"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostáváte"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o obchod"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit účet"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet není vybrán"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka nespadá do rozmezí:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odevzdávané"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímané"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dávám"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interní"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dostávám"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní aktiva"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva nebyla nalezena"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktiva"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externí"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybráno:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["včera"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádat o arbitráž"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí aktiva"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázáno na aktivum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky měření"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období od"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období do"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná aktiva"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dnes"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včera"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týden"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázáno na aktivum"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit částky"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekací doba (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka je aktivní"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze po odkazu"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pouze ověřeným uživatelům"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit externí aktivum"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyma"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřadit k aktivu"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotky měření"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkráceně"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesnost"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat vše"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomaty"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikněte pro rozbalení tabulky na celou obrazovku"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdealeři"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte si bankomat"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit kryptomat"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní informace"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlavní"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevírací doba"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací jméno"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis místa instalace"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalizovaný"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenormalizovaný"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozní režim"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondělí"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úterý"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Středa"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtek"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pátek"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neděle"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obědová přestávka"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat heslo"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použijte existující účty"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte nové účty"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní údaje"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provozní doba (informace)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné šifrovací stroje"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo bankomatu"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeměpisná šířka"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeměpisná délka"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linka pomoci"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID subdealera"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účty"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzitní účet"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření kryptomatu"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava kryptomatu"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázat"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sběratelů"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte sběratele"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze přejít na inkasní zůstatky – žádná data"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam sběratelů"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte sběratele"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoleno"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijetí"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit bankomat"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit aktuální inkasní zůstatky"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vyložené zůstatky sbírky"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN kód"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud neurčíte subdealera, bude s vámi bankomat spojen"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeměpisná šířka a délka jsou zeměpisné souřadnice, které lze zkopírovat například z Map Google zadáním adresy bankomatu"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo instalace"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro zobrazení tabulky musí být vybrán alespoň jeden sloupec"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronické kontroly"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte šek"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odepisovací účet"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivně"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné kontroly"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivovat"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šek"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření elektronické účtenky"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odepisovací účet"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označení každého šeku"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kontrol"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadní"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šeky vytvořeny"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývozní"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivace elektronické kontroly"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vkladový účet"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivační kód"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šek"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivovat"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jejda, došlo k chybě!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr z bankomatu"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat sbírku"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat archivní sbírky"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazovat zrušené sbírky"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavení"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné sbírky"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o inkaso podána"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit sbírku"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit stav sbírky"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidávání sbírky"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní informace"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základy"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávkovač"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet karet na aplikaci"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce faktury"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sběratelé hotovosti"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka pro sběratele"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke sbírce"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání mimo provoz"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyměňte kazetu akceptoru bankovek"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kartám"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjměte všechny karty z kazety dávkovače Reject Card"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyjměte všechny karty z tuby"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bodu je povinné pole, které je nutné vyplnit"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet bankovek v kazetě 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství v kazetě 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet bankovek v kazetě 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k kazetě 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava sbírky"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo kazety"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kazety"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo sbírky kazet"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení kazety"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahráno"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastně"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář ke kazetě"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná data k zobrazení"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka zařízení"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platný"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podle"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet účtů"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dávkovač"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout/Odvolat"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce faktury"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout karty"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proud"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vyloženo"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkasní zůstatky z bankomatu"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka zařízení"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platný"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kazeta instalovaná v bankomatu"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zabaveno"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatky jsou odneseny"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prázdná kazeta:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přilnavost:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["k dnešnímu dni"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte stav"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o inkaso byla podána"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihláška k odběru byla zrušena"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbírka schválena k provedení"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvednutí provádí pokladní (peníze se vkládají do kazet)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazety předané sběratelům (peníze na cestě)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr hotovosti nainstalovaný na bankomatu"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V důsledku poruchy přetížení jinou kolekcí"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzvednutí bylo nahrazeno jiným vyzvednutím v bankomatu (zůstává na cestě)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkasní zůstatky jsou zaúčtovány (sbírka je odeslána do archivu)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit informace o kolektoru"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr účtu navrhovatele"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole formuláře č."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybí požadované ID"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně jste se přihlásili, pokračujte v operacích na bankomatu"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračujte v transakcích z bankomatu"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte sloupce, které chcete zobrazit"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit zprávu"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochutnat"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní účet"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemní účet"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronymic: (pokud existuje):"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatelná jsou písmena latinky, pomlčka a apostrof."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena jsou přijatelná, stejně jako pomlčka, mezera a apostrof."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena jsou přijatelná, stejně jako pomlčka, uvozovky, mezera, číslice a apostrof."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latinská písmena (velká a malá), číslice, symboly _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte heslo"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesla se neshodují"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelská data"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte správnou emailovou adresu nebo telefonní číslo. Telefonní číslo uvádějte v mezinárodním formátu, například: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně jste se zaregistrovali"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní můžete"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přihlásit se do osobního kabinetu"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet bude ověřen naším zaměstnancem, o výsledcích jeho aktivace vás budeme informovat později"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email byl odeslán s odkazem na potvrzení registrace"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vaši emailovou adresu"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po prvním přihlášení vám bude nabídnuto potvrdit telefonní číslo"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjemce:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel nenalezen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr uživatelů"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakce"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné parametry"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna zůstatků na účtech"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na konci operace:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na začátku operace:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další parametry"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace v blockchainu"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další pokus:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpověď"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chráněné převody"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ochrany:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód ochrany:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry šablony"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit operaci"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat operaci"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit šablonu"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo transakce"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operace"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum platby"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo platebního formuláře"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příchod/Výdej"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna zůstatků adres"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit připsání"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit peníze odesílateli"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podezřelá operace"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti akcí:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel nenalezen"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte potvrzovací heslo"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakovat operaci"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie operací"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam účtů"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámá chyba"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktiva"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o novém aktivu"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další problém"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na blockchain"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstup:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte adresu"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osada"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Městská část"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osada"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulice"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další adresa"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upřesnění"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dům"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o technickou podporu"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat zprávu"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete nahrát jeden nebo více souborů. Maximální velikost souboru je 10 MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text zprávy"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření widgetu pro"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vygenerovat"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba v"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka platby"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce při úspěchu"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce při neúspěchu"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generování kódu/odkazu pro platbu"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Č. operace obchodu"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další parametr"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšný URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neúspěšný URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL oznámení"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po úspěšné platbě"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam se klient vrátí po neúspěšné platbě"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, kam bude odesláno oznámení o výsledcích platby"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixovat částku"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na platbu"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentace"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba platnosti účtu na platbu v minutách, výchozí 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba platnosti odkazu"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit obchod"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud to vidíte, formulář nebyl získán (ale neměli byste to vidět)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatečná emise"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoměna"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet – zdroj krytí"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetní účet provize"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další problém"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost aktuálního problému"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŽETON"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povlak"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet pokrytí"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazba:"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K odepsání"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K připsání"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat soubor"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolené formáty:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámý formát"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příliš velká velikost souboru, maximální velikost:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nebo přetáhněte sem soubory pro nahrání"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory nahrány"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soubory"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledek:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit stav"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užitečné odkazy"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další nastavení zabezpečení"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžaduje se potvrzení"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizaci je třeba potvrdit jednorázovým klíčem"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizaci je třeba potvrdit kouzelným slovem"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registraci je třeba potvrdit kódem z sms zprávy"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řádek od"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znaků"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná hodnota"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím vyberte, které sloupce chcete zobrazit"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobré ráno"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobré odpoledne"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrý večer"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrou noc"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlášení"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazeno:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celkem"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stránce"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit platbu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítat poplatek za vrácení"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit platbu"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash v blockchainu"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML riziko"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komise"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K připsání"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud souhlasíte se změněnými podmínkami, potvrďte připsání:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud vám tyto podmínky nevyhovují, můžete vrátit prostředky na účet odesílatele po odečtení nákladů na vrácení:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na částku"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["má AML riziko"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proto byla přepočítána komise za její připsání"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tudíž částka k připsání činí"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové příjmy nebyly zjištěny"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum platby"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadní"])}
      }
    }
  }
}