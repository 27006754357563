export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum ersten Mal hier?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederherstellen"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon oder E-Mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederherstellen"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlerer Name:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer gefunden"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter/Kommentar"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Operationen gefunden"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag der Operationen"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Stk. auf den Betrag"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationstypen"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungen, Bezahlung von Dienstleistungen"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Überweisungen"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungszahlung"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen zwischen eigenen Konten"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung von Krypto-Checks"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierung von Krypto-Schecks"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen/Ausgaben"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= gleich"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ ungleich"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> größer"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ größer oder gleich"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< kleiner"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ kleiner oder gleich"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich abgeschlossen"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationsgeschichte"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauffüllung"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börsenwechsel"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressauffüllung"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen innerhalb der eigenen Brieftasche"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaler Pass"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdatum:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Autorisierung:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllen"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abheben"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konten"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zur Bestätigung"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Transaktionen zu bestätigen, müssen Sie ein Bestätigungskennwort erstellen."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebte Zahlungen"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausblenden"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdaten"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlich zugänglich"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Gruppe"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsgruppen"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsbetrag"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungskonto"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisen"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllkonto"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauffüllung"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsdetails"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Überweisung"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsinformationen"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederauffüllung der Krypto-Adresse"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoadresse für den Nachschub"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Aufgabenliste"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsadresse"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation wiederholen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationsgeschichte"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenliste"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenliste"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungskonto:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsschutz"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzdauer:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Vorlage speichern"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisen"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung an einen anderen Benutzer"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schutzcode ist der Code, den der Empfänger eingeben muss, um die Transaktion abzuschließen. Der Empfänger sieht die geschützte Überweisung sofort im Abschnitt \"Operationsgeschichte\", kann das Geld jedoch erst verwenden, nachdem er den Schutzcode in der Transaktionskarte eingegeben hat. Wenn die Zahlungsfrist abgelaufen ist, werden die Mittel an den Absender zurückgegeben."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsadresse"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisen"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung zwischen eigenen Konten"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen innerhalb der Brieftasche"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Adresse erstellen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto erstellen"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte löschen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenliste"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoname"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Konten wirklich löschen?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Konto wirklich löschen"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte gefunden"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Konten gefunden"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressumbenennung"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Adressen wirklich löschen?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Adresse wirklich löschen"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresstyp"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotyp"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse erstellen"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Adresse erstellen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto erstellen"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antiphishing-Einstellung ist ein zusätzlicher Schutzmechanismus bei der Arbeit mit dem Schrank."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen festgelegte Begrüßungsphrase wird im oberen Bereich jeder Schrankseite angezeigt."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die richtige Phrase zeigt Ihnen an, dass Sie sich auf der richtigen Website befinden. Eine falsche Phrase ist ein Signal: ohne Operationen durchzuführen, müssen Sie sich dringend mit der Sicherheitsabteilung unseres Systems in Verbindung setzen."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing aktivieren:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing-String:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen werden nach der nächsten Autorisierung wirksam"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IP-Zugangseinstellung verhindert, dass Sie (oder ein Angreifer) mit dem System von einer Adresse arbeiten, die nicht auf der Liste der erlaubten Adressen steht."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adressen-beschränkter Zugang zum Schrank"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen hinzufügen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressblock: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressliste"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Adresse"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis Adresse"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Adressen"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verweigern"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adresse eingeben"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche IP-Adresse eingegeben"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse oder Adressblock angegeben."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine IP-Adresse zur Autorisierung erlaubt. Sie können nicht auf Ihr Konto zugreifen."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Zugang"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das magische Wort ist eine zusätzliche Sicherheitseinstellung."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das magische Wort aktiviert ist, wird das System bei jeder Autorisierung Sie auffordern, bestimmte Symbole (nach ihrer Reihenfolge) aus dem magischen Wort einzugeben."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort abfragen:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Schranks:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Bezahlen über den Händler:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verwendung der Karte:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magisches Wort"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel erhöhen die Sicherheit des Systems erheblich."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Betreten des Programms werden Sie nicht nur aufgefordert, den Login und das Passwort einzugeben, sondern auch den nächsten einmaligen Schlüssel. Die Aktivierung erfolgt nacheinander. Der letzte Schlüssel ist gültig, bis Sie eine neue Schlüsselserie erstellen."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel verwenden"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselliste"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Schlüssel"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Neues Passwort\" und \"Passwortbestätigung\" stimmen nicht überein."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von 4 bis 63 Zeichen. Erlaubte Zeichen sind lateinische Buchstaben, Zahlen und Symbole"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortbestätigung:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Neues Passwort\" und \"Passwortbestätigung\" stimmen nicht überein."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf keine kyrillischen Buchstaben enthalten"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bestätigungspasswort erstellen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie das Bestätigungspasswort"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Hauptseite"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von 4 bis 65 Zeichen. Erlaubte Zeichen sind lateinische Buchstaben, Zahlen und Symbole"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das Passwort für den Zugang zum Schrank vergessen, können Sie es wiederherstellen, indem Sie die geheime Frage beantworten."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Abschnitt können Sie die geheime Frage/Antwort ändern."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime Frage"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Frage:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime Frage"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Frage"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelspaar:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umtauschtyp:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktüberweisung - es wird der günstigste Kurs zum Zeitpunkt der Auftragserteilung verwendet. Achtung, der zu erhaltende Betrag kann sich je nach den Aufträgen zum Zeitpunkt des Umtauschs ändern und vom berechneten Betrag abweichen."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung zu einem festen Preis erfolgt gemäß dem von Ihnen angegebenen Betrag und Kurs. Ein Auftrag wird erteilt. Wenn der angegebene Kurs stark vom Marktpreis abweicht, kann es lange dauern, bis der Auftrag abgeschlossen ist."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs (Prognose):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs nicht definiert,"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es wird empfohlen, den Kurs selbst festzulegen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles verwenden"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Markt"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(schneller Austausch zum besten Gegenangebot)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fester Kurs"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Auftrag zu einem festgelegten Kurs erteilen)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine passenden Konten"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung in 24 Stunden"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwankungen in 24 Stunden"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsvolumen in 24 Stunden"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag darf nicht null sein!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austauschen"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Zusammenfassungsdaten zu sehen, wählen Sie das Handelspaar aus"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht erlaubt, gleiche Vermögenswerte auszutauschen."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einschränkungen"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Börse"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktübersicht"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Angebote"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenangebote"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufträge"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Geschäfte"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeben"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum und Uhrzeit"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angebote"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geschäfte"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aufträge"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig ausgeführt"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung nicht gestartet"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung gestartet"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig storniert"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt und storniert"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aufträge"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aufträge"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aufträge"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaler zu gebender Betrag"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler zu gebender Betrag"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaler zu erhaltender Betrag"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler zu erhaltender Betrag"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Versuche, die Transaktion durchzuführen"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu gebende Währung"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu erhaltende Währung"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastungskonto"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschriftkonto"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Subhändler"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumstyp nicht ausgewählt"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Teile sind fest und welche variabel"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu gebender Betrag wird festgelegt"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu erhaltender Betrag wird festgelegt"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragstyp"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer möchte schnell zum besten Gegenangebot tauschen"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch zu einem festgelegten Kurs"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung nicht gestartet"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung gestartet"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig ausgeführt"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig storniert"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise ausgeführt und storniert"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragenes Aktiv"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangenes Aktiv"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumstyp"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu gebender Betrag"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu erhaltender Betrag"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angebote"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P-Angebote"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer-ID"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotscode"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer verifiziert"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion mit sich selbst nicht möglich"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv auswählen"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Krypto-Assets"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token-Ausgabe"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzept"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markt"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primär"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundär"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit einem anderen Aktiv abgeglichen"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Informationen zum Krypto-Asset"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Sie Daten zu einem Krypto-Asset"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliches Problem"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset löschen"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEIN"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens können an einem Kryptomaten erworben werden"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens können an einem Kryptomaten verkauft werden"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token anzeigen"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen bestimmten Benutzer"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-UID"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name ist der vollständige Name des Krypto-Assets, wie er von allen gesehen wird: vom Emittenten selbst, von Käufern, Verkäufern, Verarbeitern usw. Die Zeichenfolge ist nicht länger als 40 Zeichen."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Ticker ist ein Kurzname für ein Krypto-Asset, eine verkürzte Abkürzung zwischen 3 und 10 Zeichen lang."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Kategorie, in die Ihr Krypto-Asset am besten passt"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützte Formate: PNG, JPG, JPEG, SVG. \nDateigröße nicht mehr als 1 MB, 500*500 Pixel"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreiben Sie, warum dieses Krypto-Asset vom Emittenten ausgegeben wird, in welchem Bereich es arbeitet oder arbeiten wird, was seine Aufgabe ist."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel / Mission / Zweck:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheitswert:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Dezimalstellen für den Betrag. Die Genauigkeit der meisten Fiat-Währungen wie US-Dollar oder Euro beträgt zum Beispiel 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Einheiten des auszugebenden Krypto-Assets. Der Ausgabebetrag wird sofort auf Ihrem Konto verfügbar sein, nachdem die Transaktion zur Erstellung des Kryptoaktivums abgeschlossen ist."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angegebener Einheitswert:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtete Person:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschluss zur Ausgabe eines virtuellen digitalen Vermögenswerts:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen in die Blockchain exportieren:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain-Typ:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Transaktionen aktivieren:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschränkungen/Sanktionen:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie an, welche Staatsangehörigen oder steuerlich Ansässigen welcher Länder aus der obigen Liste nicht berechtigt sind, Krypto-Assets in Umlauf zu bringen:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Beschränkungen und Bedingungen:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting-Adresse:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whitepaper-Adresse:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Kontakte:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset erstellen"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Zweck"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Merkmale"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export in die Blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits und Beschränkungen"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht definiert"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste Bindung an Fiat-Währungen und andere Vermögenswerte"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittent (Person, die ein Krypto-Asset ausgibt)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter (Person, die ein Krypto-Asset vertreibt)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absender"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich (Sponsoring aktivieren)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset-Platzierung"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset bezeichnet eine digitale Darstellung eines Wertes oder Rechts, die elektronisch mit Hilfe der Blockchain-Technologie übertragen und gespeichert werden kann. Krypto-Assets umfassen NICHT Fiat-Währungen, Wertpapiere und andere Finanzinstrumente und Vermögenswerte, die einer gesonderten staatlichen Regulierung unterliegen."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-Asset bearbeiten"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Ablehnung der Annahme"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto des Anbieters"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto des Anbieters in der verbindlichen Währung"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anbieter ist ein Vertreter des Emittenten eines Vermögenswerts bei Kauf-/Verkaufstransaktionen auf dem Primärmarkt. \nDas Konto ist passiv. \nKann ein Vermögensausgabekonto sein"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konto ist passiv, währungsgebunden. \nMuss demselben Benutzer gehören wie das vorherige Konto"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermöglichen Sie Benutzern den Kauf eines Vermögenswerts an Geldautomaten"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket-Kaufformular"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärmarkttransaktionen werden über Gateways abgewickelt"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket-Verkaufsformular"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärmarkttransaktionen werden über Gateways abgewickelt"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie Transaktionen mit einem Vermögenswert an der Börse"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie Vermögenstransaktionen über P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie ein Einzelunternehmer oder ein Vertreter einer juristischen Person sind, müssen Sie die Entscheidungsdatei über die Freigabe eines digitalen Vermögenswerts im PDF-Format herunterladen. \nDie Lösung muss alle wesentlichen Merkmale des virtuellen Vermögenswerts enthalten und mit einer elektronischen Signatur des einzelnen Unternehmers oder dieser juristischen Person versehen sein. \nEin Beispiel für eine Entscheidung zur Ausgabe eines digitalen Vermögenswerts finden Sie auf den Seiten unserer Website im Bereich „Dokumente“."])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzieren eines virtuellen Assets"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersatzausgabekonto"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus Emittentenkonten aus"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelle Vermögenswerte"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Operationen benachrichtigen"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung (Anmeldung im persönlichen Konto, mobile App usw.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigen bei Ereignis „Ausführung von Finanztransaktionen mit der Karte“"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben (jede Zahlungen, Überweisungen, Dienstleistungszahlungen usw.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldeingänge"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang einer Nachricht über die interne Post"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang einer Rechnung zur Zahlung"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Sicherheitseinstellungen"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen mit Code aus der Nachricht bestätigen"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung mit Code aus der Nachricht bestätigen"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung bei „Ausführung von Finanztransaktionen mit der Karte“"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Sicherheitseinstellungen mit Code aus der Nachricht bestätigen"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben mit Code aus der Nachricht bestätigen"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen nicht gespeichert, speichern?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht speichern"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdatum"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitungsdatum"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zahlungen"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einschließlich erfolgreicher"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einschließlich fehlerhafter"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenüberweisungen"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Parameter"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zahlungen gefunden"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können mehrere Zahlungen gleichzeitig durchführen. Dazu müssen Sie vorab eine Datei mit einer Beschreibung der Zahlungen im Format \"XLS\", \"XLSX\", \"CSV\" oder \"XML\" erstellen."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateianalyse"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiinformationen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtanzahl der Zahlungen: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbuchungskonto"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen durchführen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei hochladen"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenüberweisungen und -zahlungen"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Massenüberweisungsdienst ermöglicht es Ihnen, schnell Geld an andere Benutzer zu überweisen und Zahlungsoperationen an Dienstleister durchzuführen."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Abschnitt"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatei hochladen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["können Sie eine Datei im Excel-Format (XLS, XLSX, CSV) oder XML hochladen."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenliste"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ermöglicht es Ihnen, die Status früherer Anfragen für Massenüberweisungen anzuzeigen."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine Datei in einem Tabelleneditor wie Excel erstellen."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispieldatei herunterladen"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiformat - CSV, XLS oder XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei sollte keine Überschriften haben."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit lange Zahlen nicht im Exponentialformat dargestellt werden (z. B. \"5,46546E+15\") und führende Nullen nicht abgeschnitten werden, können Sie vor der Zahl ein einfaches Anführungszeichen (') setzen oder der Zelle vor der Eingabe der Daten das Textformat zuweisen."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Zahlungen an Dienstleister"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Spalten in der Datei:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wird aus der Liste der Betreiber ausgewählt"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Für alle Mobilfunkbetreiber können Sie den Betreiber-Code 7000 angeben - in diesem Fall wird der Betreiber automatisch anhand der Telefonnummer bestimmt.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере актива, с которого осуществляется перевод. Если тикер счета, на который переводятся активы, отличается от тикера счета, выбранного для перевода, к операции автоматически применяется системный коэффициент конвертации."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskommentar"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Geben Sie den vom Betreiber geforderten Parameterwert ein, z. B. Telefonnummer. Viele Betreiber beschränken sich auf einen Zahlungsparameter, aber wenn die Anzahl der Parameter mehr als eins beträgt, müssen Sie alle in den benachbarten Spalten zum ersten Parameter angeben."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Zahlungsparameter anzeigen, indem Sie zur Zahlung eines bestimmten Betreibers gehen:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Massenüberweisungen an Benutzer"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Geben Sie 0 ein oder lassen Sie es leer."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере счета, с которого осуществляется перевод актива. Если такого тикера на счетах получателя нет, к операции автоматически применяется системный коэффициент конвертации."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskommentar"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfängerkonto"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Hier geben Sie die Kontonummer des Benutzers ein, dem die Überweisung erfolgen soll (20 Ziffern)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenformat für Massenauszahlungen durch einen Kreditagenten"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Diese Zeile zeigt an, dass die Zahlungen Kredite sind."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Der Betrag wird in der Währung des Kreditprodukts angegeben."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkommentar"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Optional, kann leer gelassen werden; Länge nicht mehr als 255 Zeichen."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darlehensnehmer-ID"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Bitte beachten Sie, dass der Darlehensnehmer einen digitalen Pass Mfo-BorrowPerson haben muss."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditprodukt-Code"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Sie können ihn in der Liste der Kreditprodukte bei manueller Darlehensvergabe sehen."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Nur die Anzahl der Perioden (welche Perioden verwendet werden, ist im Kreditprodukt festgelegt, z. B. Tage, Wochen, Monate usw.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber-Code (Formularnummer)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Das Ausfüllen dieses und der folgenden Felder ist optional. Wenn sie nicht ausgefüllt werden, kann der Darlehensnehmer das Darlehen später selbstständig über sein persönliches Konto abheben."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Fehlerfall stornieren"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Stornieren (1) des gewährten Darlehens, wenn die Auszahlungs-Transaktion fehlschlägt, oder für eine spätere selbstständige Auszahlung belassen (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Geben Sie den vom Betreiber geforderten Parameterwert ein, z. B. Telefonnummer. Viele Betreiber beschränken sich auf einen Zahlungsparameter, aber wenn die Anzahl der Parameter mehr als eins beträgt, müssen Sie alle in den benachbarten Spalten zum ersten Parameter angeben."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsparameter N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Nachricht"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anfragen"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine oder mehrere Dateien hochladen. Maximale Dateigröße - 10MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrig"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoch"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Support"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Anfragen"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Anfrage"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird vom Operator bearbeitet"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage erstellt"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage in Bearbeitung, warten Sie auf die Antwort des Operators"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage geschlossen"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage geschlossen"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Nachrichten"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Nachricht vom Operator"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Nachrichten"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ins Archiv senden"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auffüllung"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiber"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felder"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vorlagen"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Vorlage Nr. wirklich löschen"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage umbenennen"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen über die Gegenpartei"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Konto / E-Mail / Telefon"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Krypto-Adresse / E-Mail / Telefon"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollsignatur"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Geschäfte"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geschäfte gefunden"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget-Code erstellen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungslink erstellen"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft löschen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Geschäft wirklich löschen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft registrieren"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmodus"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft registrieren"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein im System registriertes elektronisches Geschäft ermöglicht es, Zahlungen von Kunden über das Merchant-System zu akzeptieren."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Geschäfte, Wechselstuben und andere Internetressourcen, die Zahlungen empfangen möchten, werden als Geschäfte registriert."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name oder Marke"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-Adresse des Geschäfts"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurze Beschreibung des Geschäfts"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungstyp"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft ist aktiv"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheimer Schlüssel"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Auszahlungen zulassen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse an das Skript senden"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skript-Adresse"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr nach erfolgreicher Zahlung"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach fehlgeschlagener Zahlung"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 30 Zeichen. Erlaubte Zeichen: lateinische Buchstaben, Zahlen"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird verwendet, um die Integrität und Richtigkeit der von der Zahlungssystem übermittelten Daten (bei der Übermittlung des Benutzers durch das Geschäft an den Merchant) und durch das Geschäft (bei der Annahme der Zahlungsergebnisse) zu überprüfen"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Liste der Geschäfte"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsliste"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Rechnungen gefunden"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aktiva"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Aktiv"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyme"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ausgefüllt, wird die Anzeige nur für Benutzer aus den angegebenen Ländern verfügbar sein"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binden Sie es an einen Vermögenswert"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßeinheiten"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkürzung"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Infos"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungsrichtung"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit (Minuten)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige ist aktiv"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur über den Link verfügbar"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer verfügbar"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum vorherigen"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschränkungen"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige erstellen"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenes externes Aktiv"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht anbinden"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs senken"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto in solchem Aktiv"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs angeben"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs ist korrekt"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs senken"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum größer als Maximum"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohin und wie möchten Sie die Überweisung erhalten. Zum Beispiel: Überweisen Sie in der Bankanwendung an die Telefonnummer +499990001111 (Empfänger Alexander N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich übergebe die"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angeforderte Angaben"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Angaben der Taker senden muss, damit Sie ihm die Überweisung machen können"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset auswählen"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset auswählen"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was möchten Sie erhalten: Mittel in einem Innenaktiv, zum Beispiel BTC, oder in einem Außenaktiv, zum Beispiel TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentieren Sie die Besonderheiten dieser Transaktion, zum Beispiel: \\\"Das Außenaktiv wird nur bei einem persönlichen Treffen übertragen\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art von Werten, zu denen das Außenaktiv gehört, zum Beispiel \\\"Bargeld\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Aktivs zur Anzeige auf der Plattform"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Aktiv kann auch mit Wörtern aus der Liste bezeichnet werden"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgleich mit einem bestehenden Aktiv"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: Liter, Stück, Stunden, Boxen"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel: L, St, Std, Box"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Dezimalstellen kann ein Vermögenswert habt"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Erstellung eines noch nicht existierenden Aktivs gewählt, bitte füllen Sie seine Merkmale aus"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit, die den Parteien zur Erfüllung der Bedingungen gegeben wird"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Innenaktiv ist ein Aktiv, das im System \\\"New Reality\\\" existiert. Ein Außenaktiv ist ein Aktiv, das nicht im System \\\"New Reality\\\" existiert."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht mit einem anderen Vermögenswert abgeglichen"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion stornieren"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahme an der Transaktion bestätigen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion ablehnen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit verlängern"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsstornierung"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerstatus in der Transaktion nicht definiert. Etwas ist schief gelaufen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiedsgericht anfordern"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schiedsgericht zustimmen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung benachrichtigen"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung bestätigen"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion bewerten"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung senden"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion Nr."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["morgen"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie geben"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Transaktionen"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteil"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionscode"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine aktiven Transaktionen"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Transaktionen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["innerer Vermögenswert"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung des Aktiv"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anzeigen"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Anzeigen"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Anzeigen"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Anzeige"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Aktiv"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internes Aktiv"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tausche"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichungsdatum"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontostand"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsanfrage"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit auf Aktionen der Parteien"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie geben"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion anfordern"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nicht ausgewählt"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Betrag liegt nicht im Bereich:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfangen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich gebe"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intern"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich erhalte"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Aktiva"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktiva gefunden"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Aktiva"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extern"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestern"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlichtung anfordern"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Vermögenswerte"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Asset gebunden"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßeinheiten"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum von"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum bis"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Vermögenswerte"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Asset gebunden"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betragslimit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartezeit (Minuten)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige aktiv"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur über den Link verfügbar"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für verifizierte Benutzer verfügbar"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externes Asset bearbeiten"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyme"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Asset binden"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßeinheiten"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkürzung"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genauigkeit"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles auswählen"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um die Tabelle im Vollbildmodus zu öffnen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhändler"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Geldautomaten"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptomat bearbeiten"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptinformationen"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsächlich"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung des Installationsortes"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalisiert"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht normalisiert"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsmodus"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittagspause"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehende Konten nutzen"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie neue Konten"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeiten (zur Information)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kryptomaschinen gefunden"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomatennummer"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhändler-ID"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transitkonto"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung eines Kryptomaten"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten eines Kryptomaten"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbieten"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Sammler"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Kollektor hinzu"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Inkassosalden können nicht aufgerufen werden – keine Daten"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Sammler"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie einen Kollektor hinzu"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten bearbeiten"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Inkassosalden anzeigen"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entladene Inkassosalden anzeigen"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN-Code"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie keinen Subhändler angeben, wird der Geldautomat mit Ihnen verknüpft"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breiten- und Längengrad sind geografische Koordinaten, die beispielsweise aus Google Maps durch Eingabe der Geldautomatenadresse übernommen werden können"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsort"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Tabelle anzuzeigen, muss mindestens eine Spalte ausgewählt sein"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Schecks"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Scheck"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschreibungskonto"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kommentar"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht aktiv"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Schecks"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren Sie"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen einer elektronischen Quittung"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschreibungskonto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückelung jedes Schecks"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Kommentar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Schecks"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schecks erstellt"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierung eines elektronischen Schecks"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einlagenkonto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungscode"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivieren Sie"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppla, da ist ein Fehler aufgetreten!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung am Geldautomaten"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung hinzufügen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochene Sammlungen nicht anzeigen"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktnummer"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sammlungen"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassoantrag eingereicht"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung bearbeiten"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlungsstatus ändern"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung hinzufügen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlagen"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spender"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktnummer"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Karten pro Anwendung"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldscheinakzeptant"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldsammler"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis für Sammler"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Sammlung"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden außerhalb der Reihenfolge"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tauschen Sie die Geldscheinkassette aus"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zu den Karten"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie alle Karten aus der Ausgabekassette für abgelehnte Karten"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie alle Karten aus der Röhre"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Punktnummer ist ein Pflichtfeld, das ausgefüllt werden muss"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Scheine in Kassette 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge in Kassette 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Scheine in Kassette 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zu Band 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung bearbeiten"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettennummer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten-ID"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettensammlungsnummer"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassettenbewertung"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomat"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentlich"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar zur Kassette"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten zum Anzeigen"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätesammlung"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomat"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Rechnungen"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spender"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen/Zurückziehen"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldscheinakzeptant"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten ablehnen"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karten"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuell"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entladen"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben bei der Abholung von Geldautomaten"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätesammlung"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gültig"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassette im Geldautomaten installiert"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beschlagnahmt"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überreste werden weggetragen"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leere Kassette:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftung:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miteinander ausgehen"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status auswählen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inkassoantrag wurde eingereicht"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassoantrag storniert"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammlung zur Ausführung freigegeben"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abholung erfolgt durch die Kasse (Geld wird in Kassetten geladen)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassetten an Sammler übergeben (Geld unterwegs)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bargeldabholung am Geldautomaten installiert"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund eines Fehlers mit einer anderen Sammlung überlastet"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abholung wurde durch eine andere Abholung an einem Geldautomaten ersetzt (bleibt unterwegs)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassosalden werden verbucht (Inkasso wird an das Archiv gesendet)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Sie die Informationen zum Collector"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl des Kontos des Anbieters"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularfelder Nr."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche ID fehlt"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich erfolgreich angemeldet, der Betrieb am Geldautomaten kann fortgesetzt werden"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie die Transaktionen am Geldautomaten fort"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die anzuzeigenden Spalten aus"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht erstellen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probe"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blatt"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Konto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftskonto"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatersname"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben, Bindestriche und Apostrophe sind erlaubt."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben sind erlaubt, ebenso Bindestriche, Leerzeichen und Apostrophe."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateinische Buchstaben sind erlaubt, ebenso wie Bindestriche, Anführungszeichen, Leerzeichen, Zahlen und Apostrophe."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt sind lateinische Buchstaben (groß und klein), Zahlen, Symbole _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine gültige E-Mail-Adresse oder Telefonnummer an. Telefonnummer im internationalen Format angeben, z. B.: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich erfolgreich registriert"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt können Sie"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sich in Ihrem persönlichen Konto anmelden"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wird von unserem Mitarbeiter überprüft, und wir werden Ihnen später über die Ergebnisse der Aktivierung informieren"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail mit einem Bestätigungslink wurde an Ihre E-Mail-Adresse gesendet"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Ihre E-Mail-Adresse"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der ersten Autorisierung werden Sie aufgefordert, Ihre Telefonnummer zu bestätigen"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger: "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nicht gefunden"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Parameter"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Salden auf Konten"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Ende der Operation:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Anfang der Operation:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Parameter"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationen in der Blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Versuch:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsschutz"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzdauer:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzcode:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenparameter"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation stornieren"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation wiederholen"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsnummer"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrespondent"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatum"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsformularnummer"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen/Ausgaben"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Adresssalden"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift bestätigen"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geld an Absender zurücksenden"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdächtige Operation"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlungsoptionen:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name: "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nicht gefunden"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Bestätigungspasswort ein"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation wiederholen"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operationsgeschichte"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontenliste"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Vermögenswerte"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum neuen Asset"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliches Problem"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zur Blockchain"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse auswählen"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezirk"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreis"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadtteil"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Adresskomponente"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklärung"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage an den technischen Support"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht hinzufügen"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine oder mehrere Dateien hochladen. Maximale Dateigröße - 10MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget-Erstellung für"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung in"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsbetrag"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsaktionen"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehleraktionen"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code / Link zur Zahlung generieren"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsoperationsnummer"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlicher Parameter"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerhafte URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungs-URL"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Kunde nach erfolgreicher Zahlung zurückkehrt"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, zu der der Kunde nach einem Fehler zurückkehrt"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, an die das Zahlungsergebnis gesendet wird"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag festlegen"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungslink"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsdauer der Rechnung in Minuten, standardmäßig 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsdauer des Links"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft bearbeiten"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dies sehen, wurde das Formular nicht empfangen (aber Sie sollten dies nicht sehen)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Emission"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto Asset"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto – Deckungsquelle"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionslastkonto"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliches Problem"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Ausgabegröße"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOKEN"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtung"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckungsberechnung"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindung:"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbuchung"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Formate:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekanntes Format"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei zu groß, maximale Größe: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien auswählen oder hierher ziehen, um sie hochzuladen"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochgeladen"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status aktualisieren"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nützliche Links"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Sicherheitseinstellungen"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung erforderlich"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung muss mit einem einmaligen Schlüssel bestätigt werden"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisierung muss mit dem magischen Wort bestätigt werden"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung muss mit einem SMS-Code bestätigt werden"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenfolge von"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Wert"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie, um auszuwählen, welche Spalten angezeigt werden sollen"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krypto-ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Morgen"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Tag"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Abend"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gute Nacht"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigt:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insgesamt"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Seite"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung bestätigen"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlungskommission berechnen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung zurücksenden"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash in der Blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-Risiko"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie mit den geänderten Bedingungen einverstanden sind, bestätigen Sie die Einschreibung:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihnen diese Bedingungen nicht zusagen, können Sie die Mittel abzüglich der Rückzahlungskosten an das Absenderkonto zurücksenden:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Betrag"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat ein AML-Risiko"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daher wurde die Kommission für ihre Gutschrift neu berechnet"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["somit beträgt der Gutschriftsbetrag"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine neuen Eingänge gefunden"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdatum"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlung"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])}
      }
    }
  }
}