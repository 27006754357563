const lib = {
  req(req, store, router) {
    if (typeof req.data == "undefined") {
      req.data = { data: {} };
    }
    if (store.state.pay_pass) {
      req.data.data.cabinet_pay_password = store.state.pay_pass;
      store.commit("PAY_PASS", false);
    }
    req.data.device_fingerprint = store.state.fingerprint;
    req.data.lang = localStorage.getItem("lang");
    if (req.data.no_loader) {
      delete req.data.no_loader;
    } else {
      store.commit("LOADER", true);
    }
    if (store.state.config.CONSOLE_LOGS) {
      console.log(req.url, req);
    }
  },

  res(res, store, router) {
    if (store.state.config.CONSOLE_LOGS) {
      console.log(res.config.url, res);
    }
    store.commit("LOADER", false);
    if (res.data.isLogged === true) {
      store.commit("IS_LOGGED", res.data.isLogged);
      if (!store.state.domains && res.data.body.domains) {
        store.commit("DOMAINS", res.data.body.domains);
      }
    } else if (res.data.isLogged === false) {
      store.commit("IS_LOGGED", res.data.isLogged);
      // store.commit('USER_DATA', {});
      if (!store.state.domains && res.data.body.domains) {
        store.commit("DOMAINS", res.data.body.domains);
      }
      if (store.state.domains) {
        let host = window.location.protocol + "//" + store.state.domains.AUTH_DOMAIN;
        window.location.href = host;
      }
      store.commit("USER_DATA", {});
      // window.location.href = 'http://auth.dev.pro';
      // if (!res.data.body.alter) {
      //     store.commit('MODAL', {
      //         action: false,
      //         type: false,
      //         content: false,});
      // }
      // store.commit('DARK_WRAPPER', false);
    } else {
      router.push("/unexpected");
    }
    if (res.data.modal) {
      if (res.data.modal == "repeat") {
        res.data.body.repeat_url = res.config.url;
      }
      if (
        this.inArray(res.config.url, ["SearchMember", "GetComiss", "checkUnique"]) &&
        res.data.modal != "questionnaire"
      ) {
        if (res.data.risk_form) {
          store.commit("MODAL_ACTION", true);
          store.commit("MODAL_TYPE", "questionnaire");
          store.commit("MODAL_CONTENT", res.data.risk_form);
        }
        return;
      }
      store.commit("MODAL_ACTION", true);
      store.commit("MODAL_TYPE", res.data.modal);
      store.commit("MODAL_CONTENT", res.data.body);
    }
    if (res.data.error) {
      if (this.inArray(res.config.url, ["GetComiss"])) {
        return;
      }
      const errorObject = {
        errorVisible: res.data.error,
        errorText: res.data.body.result_text,
      };
      store.commit("SET_WARNING", errorObject);
      // if (
      //   res.data.body.result != "4020" &&
      //   res.data.body.result != "4010"
      // ) {
      //   alert(`ERROR: ${res.data.body.result_text}`);
      // }
    }
    if (res.data.body && res.data.body.updating) {
      if (res.data.body.updating.keyts) {
        store.commit("USER_DATA_KEYTS", res.data.body.updating.keyts);
        store.commit("GLOBAL_KEY_KEYT");
      }
      if (res.data.body.updating.templates) {
        store.commit("USER_DATA_TEMPLATES", res.data.body.updating.templates);
      }
      if (res.data.body.updating.user_info) {
        let inf = res.data.body.updating.user_info;
        store.commit("USER_DATA_INFO", inf);
        if (inf.avatar) {
          let av = "";
          if (inf.avatar.value.length > 0) {
            av = inf.avatar.value[0].body;
          }
          store.commit("USER_DATA_AVATAR", av);
        }
      }
    }
    if (res.data.risk_form) {
      store.commit("MODAL_ACTION", true);
      store.commit("MODAL_TYPE", "questionnaire");
      store.commit("MODAL_CONTENT", res.data.risk_form);
    }
  },

  err(err, store, router) {
    store.commit("LOADER", false);
    if (err.response) {
      alert(`CODE: ${err.response.status}\n ${err.response.statusText}`);
    } else {
      alert("Unexpcected Http Error");
    }
  },

  checkRegExp(str, reg) {
    let regexp = new RegExp(reg);
    return regexp.test(str);
  },

  alertE(store, body) {
    store.commit("MODAL_ACTION", true);
    store.commit("MODAL_TYPE", "answer");
    store.commit("MODAL_CONTENT", body);
  },

  isInt(x) {
    var y = parseInt(x);
    if (isNaN(y)) return false;
    return x == y && x.toString() == y.toString();
  },

  HardcodeCurrPrecision(curr, amount) {
    return amount.split(".").pop().length || 2;
  },

  inArray(curr, arr) {
    if (arr.indexOf(curr) != -1) {
      return true;
    } else {
      return false;
    }
  },

  RandomDigit(len) {
    var r;
    var digit = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");

    let pasw = "";

    for (var i = 0; i < len; i++) {
      r = Math.floor(Math.random() * 9);
      pasw += digit[r];
    }
    return pasw;
  },

  cRound(num) {
    return Math.floor(num * 100) / 100;
  },

  getTokenString(str, lng) {
    let s = str.substring(0, lng);
    s += "...";
    s += str.substring(str.length - lng, str.length);
    return s;
  },

  sortArr(k, s) {
    let arr = k;
    // console.log(arr)
    if (!Array.isArray(arr)) {
      console.log(new Error("Sorted list is not Array!"));
      return arr;
    }
    if (arr.length <= 1) {
      return arr;
    }
    // console.log(arr)
    if (s.ind && typeof arr[0][s.ind] !== "undefined") {
      arr.sort((a, b) => {
        let r = 0;
        let x, y;
        if (s.type == "date") {
          x = new Date(a[s.ind]);
          y = new Date(b[s.ind]);
        } else if (s.type == "int") {
          x = Number(a[s.ind]);
          y = Number(b[s.ind]);
        } else {
          x = a[s.ind];
          y = b[s.ind];
        }
        if (x >= y) {
          s.to ? (r = -1) : (r = 1);
        } else if (x < y) {
          s.to ? (r = 1) : (r = -1);
        }
        // console.log(r)
        return r;
      });
      // console.log(k)
    }
    return arr;
  },
  precRound(context, num, prec) {
    if (prec > 15) {
      try {
        return this.toLongFixed(num, prec);
      } catch (err) {
        console.log(err);
        return "undefined";
      }
    } else {
      try {
        return context.$math.format(context.$math.round(num, prec), { notation: "fixed" });
      } catch (err) {
        console.log(err);
        return "undefined";
      }
    }
  },
  toLongFixed(number, precision) {
    number = Number(number);
    if (number === 0) {
      return "0";
    }

    const strNumber = number.toString();
    const decimalIndex = strNumber.indexOf(".");
    if (decimalIndex === -1) {
      return strNumber;
    }

    const nextCharIndex = decimalIndex + precision + 1;
    if (strNumber[nextCharIndex] !== "0") {
      const roundedNumber = Number(strNumber.slice(0, nextCharIndex)) + 1e-15; // Adding a small epsilon to ensure correct rounding
      return $math.format(roundedNumber.toFixed(precision), { notation: "fixed" });
    } else {
      return $math.format(Number(strNumber.slice(0, nextCharIndex)).toFixed(precision), { notation: "fixed" });
    }
  },
  ev(context, exp) {
    try {
      return context.$math.format(context.$math.evaluate(exp), { notation: "fixed" });
    } catch (err) {
      console.log(err);
      return "undefined";
    }
  },
  getRate(context, prec_rate, rate, from_text, to_text, revers = false) {
    let rate_from, rate_to;
    if (Number(rate) >= 1) {
      rate_from = revers ? "1" : String(rate);
      rate_to = revers ? String(rate) : "1";
    } else {
      rate_from = revers ? this.ev(context, `1 / ${rate}`) : "1";
      rate_to = revers ? "1" : this.ev(context, `1 / ${rate}`);
    }

    return `<span>${this.precRound(
      context,
      rate_from,
      prec_rate
    )} <span class='grey fs_08'>${from_text}</span> = ${this.precRound(
      context,
      rate_to,
      prec_rate
    )} <span class='grey fs_08'>${to_text}</span></span>`;
  },
  getCorrespondentName(deal, userId) {
    if (deal.internal_sender_uid == userId) {
      return deal.internal_recipient_name ? deal.internal_recipient_name : deal.internal_recipient_uid;
    }

    return deal.internal_sender_name ? deal.internal_sender_name : deal.internal_sender_uid;
  },
  cutDescription(str, length) {
    if (str.length > length) {
      return str.substring(0, length) + "…";
    }
    return str;
  },
  getClassDeal(status) {
    // сделка активна
    if (status == "10" || status == "20" || status == "30" || status == "40") {
      return "color-acent";
    }

    // успешно завершена
    if (status == "100") {
      return "color-green";
    }

    // отменена
    if (status == "130") {
      return "grey";
    }

    // арбираж
    if (status == "50" || status == "60") {
      return "color-warning";
    }

    // иной статус
    return "color-dark";
  },
  copyText(inputid) {
    let textarea = document.createElement("textarea");
    textarea.id = "temp";
    textarea.style.height = 0;
    document.body.appendChild(textarea);
    textarea.value = document.getElementById(inputid).value;
    let selector = document.querySelector("#temp");
    selector.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  },
  checkIncassData(data) {
    const advanced = data.advanced;
    const tables = data.tables;

    let result = false;

    if (tables.length != 0) {
        tables.forEach(item => {
            if (item.colvalues.length != 0) {
                result = true;
            }
        })
    }

    if (advanced.incass_validator == '1' || 
        advanced.incass_card == '1' || 
        advanced.incass_card_reject == '1' || 
        advanced.incass_card_rr == '1'
    ) {
        result = true;
    }

    return result;
  },
  incassBalance(context, num_point, status) {
    context.axios.post('GetCountersIncassAtmRest', {data: {num_point: num_point, group_status: status}}).then(res => {
        if (res.data.body.result != '0') {
            return;
        }

        const resultCheckData = this.checkIncassData(res.data.body);
        
        // проверка наличия данных
        if (resultCheckData) {
            // запись данных в store
            context.$store.commit('SAVE_ATM_INCASS_REST_DATA', res.data.body);
            // переход
            context.$router.push('/ATM/atm_incass_balance');
            return;
        }

        // вывод ошибки
        const errorObject = {
            errorVisible: true,
            errorText: context.$t('views.ATM.60')
        }
        context.$store.commit("SET_WARNING", errorObject);
    })
  },
};

export default lib;
